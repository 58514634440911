import React from 'react';
import { Form, FormGroup, Input, Label, Col, Row, Card, CardBody, Container } from 'reactstrap';

// const AppCurrency = () => {

//     const [number, setNumber] = React.useState(111);
    

//     return(
//         <Container>
//             <CurrencyInput value={number} onChange={(e) => setNumber(e.target.value)} />
//         </Container>
//     )
// }

const CurrencyInput = props => {

  // const [number, setNumber] = React.useState(31232);

  const splice = (orgStr, idx, rem, newStr) => {
    console.log(typeof orgStr)
    return orgStr.slice(0, idx) + newStr + orgStr.slice(idx + Math.abs(rem));
  };

  const addDecimal = (val) => {
      val = val.replaceAll('.', '')
      return splice(val, -2, 0, '.')
  }

  const onInputChange = (e) => {
    // check if event is from type or paste
    let inputType = e.nativeEvent.inputType;
    let val = e.target.value;

    val = val.replaceAll(/[^\d.]/g, '');

    if(inputType === 'insertFromPaste'){
      if(!val.includes('.')){
        val = val + '.00'
      } else {
        // check if there are more than 1 decimals in number
        if (val.replace(/[^.]/g, "").length > 1)
          val = val.replace('.', '')
        if (val.slice(-2, -1) === '.')
          val = val + '0';
        else if(val.slice(-1) === '.')
          val = val + '00'
        else 
          val = parseFloat(val).toFixed(2)

        e.target.value = val;

        return props.onChange(e)
      }
    }

    e.target.value = currencyValue(val, inputType)

    props.onChange(e);
  }

  const currencyValue = (value, inputType) => {
    let val = value;
  
    // check input type

    if (val.startsWith('0.00'))
      val = val.slice(4)
    else if (val.startsWith('0.0'))
      val = val.slice(3);
    else if (val.startsWith('0.'))
      val = val.slice(2);

    val = val.replace('.', '')

    if (val.length === 2)
      val = '0' + val;
    else if (val.length === 1)
      val = '00' + val;
    else if (val.length === 0)
      val = '000'

    val = addDecimal(val)

    return val
  }

  console.log(props.value)

    return(
      <Input className="form-control currency-input" name={props.name} id={props.id} style={{ textAlign: 'right'}}
      placeholder="0.00"
      value={!!parseFloat(props.value) === false ? '' : parseFloat(props.value).toFixed(2)}
      onChange={onInputChange} disabled={props.disabled} />
    )
}

export default CurrencyInput;