import React, { useState, useMemo, useEffect, useContext } from 'react';
import { Card, CardBody, FormGroup, Label, Input, Form, Row, Col, Button, InputGroupAddon, InputGroup, CustomInput, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from 'reactstrap';
import { confirm } from "./Confirm";
import CustomCKEditor from '../utility-components/CustomCKEditor';
import { Helmet } from 'react-helmet';
import PageLock from '../utility-components/PageLock';
import Select from 'react-select';

import PageTitle from '../components/PageTitle';
//icons
import { Icon } from '@iconify/react';
import pencilIcon from '@iconify/icons-mdi/pencil';
import contentCopy from '@iconify/icons-mdi/content-copy';
import contentSave from '@iconify/icons-mdi/content-save';
import youtubeIcon from '@iconify/icons-mdi/youtube';
import dragIcon from '@iconify/icons-mdi/drag';
import trashCanOutline from '@iconify/icons-mdi/trash-can-outline';
import informationOutline from '@iconify/icons-mdi/information-outline';

import { OmniApi } from '../customAPI/OmniApi';
import axios from 'axios';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ImageUpload from './ImageUpload';
import ReactPlayer from 'react-player/youtube';
import { toast } from 'react-toastify';
import { UserContext } from '../custom-contexts/UserContext';

const VariantDetail = props => {

    const { user } = useContext(UserContext);

    const [product, setProduct] = useState('');

    const [variant, setVariant] = useState('');

    const [listState, setListState] = useState(null);

    const [unsavedChanges, setUnsavedChanges] = useState(false);

    function isEmpty(obj) {
        return Object.keys(obj).length === 0;
    }

    useEffect(() => {

        let prodToken = props.match.params.prodToken;
        let variantToken = props.match.params.variantToken;

        OmniApi.get(`/products/${prodToken}/full`)
            .then(res => {
                if (isEmpty(res.data))
                    throw new Error('Invalid Product Token')
                setProduct(res.data)
            })
            .catch(err => {
                props.history.replace('/omni/catalog/products')
                toast.error(err.message)
            })

        OmniApi.get(`variants/${variantToken}`)
            .then(res => {
                if (isEmpty(res.data))
                    throw new Error('Invalid Variant Token')
                setVariant(res.data)
            })
            .catch(err => {
                props.history.replace('/omni/catalog/products')
                toast.error(err.message)
            })

    }, [])

    const [slugDisabled, setSlugDisabled] = useState(true);

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
    }

    const mdeOptions = useMemo(() => {
        return {
            maxHeight: '170px',
            hideIcons: ['fullscreen'],
            sideBySideFullscreen: false
        }
    }, []);

    const handleInputChange = (e) => {
        const target = e.target;
        const name = target.name;

        if (name === 'inventoryCount' && !`${target.value}`.includes('.'))
            setValidState({
                ...validState,
                inventoryCount: true
            })

        if (name === 'variantLowlevel' && !`${target.value}`.includes('.'))
            setValidState({
                ...validState,
                variantLowlevel: true
            })

        if (name === 'variantWeight' && target.value >= 0) {
            setValidState({
                ...validState,
                variantWeight: {
                    ...validState.variantWeight,
                    notNegative: true
                }
            })
        }

        if (name === 'variantWeight' && (variant.shippingProduct && target.value > 0)) {
            setValidState({
                ...validState,
                variantWeight: {
                    ...validState.variantWeight,
                    shippingWeightAssigned: true
                }
            })
        }

        if (name === 'variantDimW' && target.value >= 0) {
            setValidState({
                ...validState,
                variantDimW: true
            })
        }

        if (name === 'variantDimH' && target.value >= 0) {
            setValidState({
                ...validState,
                variantDimH: true
            })
        }

        if (name === 'variantDimL' && target.value >= 0) {
            setValidState({
                ...validState,
                variantDimL: true
            })
        }

        //prevent float numbers
        // DOES NOT WORK
        // if (name === 'inventoryCount' || name === 'variantLowlevel'){
        //     let val = target.value;
        //     val = `${val}`.replaceAll('.', '')
        //     val = parseInt(val)
        //     return setVariant({
        //         ...variant,
        //         [name] : val
        //     })
        // }

        // // prevent negative numbers
        // DOES NOT WORK
        // if (name === 'variantWeight' ||
        //     name === 'variantDimW' ||
        //     name === 'variantDimH' ||
        //     name === 'variantDimL'
        // ) {
        //     console.log(value)
        //     let newValue = (value).toString().replace('-', '')
        //     console.log(newValue)
        //     // val = parseInt(val)
        //     return setVariant({
        //         ...variant,
        //         [name] : newValue
        //     })
        // }
        if (name === 'variantSlug')
            e.target.value = generateSlug(e.target.value)

        setUnsavedChanges(true)

        setVariant({
            ...variant,
            [name]: e.target.value
        })
    }

    const handleSpecialValueChange = (e, index) => {
        const target = e.target;
        const name = target.name;

        setUnsavedChanges(true)

        let newVariant = {
            ...variant
        }

        newVariant.specialValues[index][name] = e.target.value;

        setVariant({
            ...newVariant
        })
    }

    const handleSpecChange = (e, index) => {
        const target = e.target;
        const name = target.name;

        setUnsavedChanges(true)

        let newVariant = {
            ...variant
        }

        newVariant.specs[index][name] = e.target.value;

        setProduct({
            ...newVariant
        })
    }

    function handleVariantChange(variant) {
        setVariant(variant)
    }

    const generateSlug = (string) => string?.toLowerCase().replace(/[^a-zA-Z0-9]+/g, "-");

    const [validState, setValidState] = useState({
        variantLowlevel: true,
        inventoryCount: true,
        variantWeight: {
            notNegative: true,
            shippingWeightAssigned: true
        },
        variantDimW: true,
        variantDimH: true,
        variantDimL: true
    })

    const validateVariant = () => {

        let newValidState = {
            ...validState
        }

        if (variant.hasOwnProperty('variantLowlevel') && variant.variantLowlevel.toString().includes('.'))
            newValidState.variantLowlevel = false

        if (variant.hasOwnProperty('inventoryCount') && variant.inventoryCount.toString().includes('.'))
            newValidState.inventoryCount = false

        if (variant.hasOwnProperty('variantWeight') && variant.variantWeight < 0)
            newValidState.variantWeight.notNegative = false

        if (variant.hasOwnProperty('variantDimW') && variant.variantDimW < 0)
            newValidState.variantDimW = false

        if (variant.hasOwnProperty('variantDimH') && variant.variantDimH < 0)
            newValidState.variantDimH = false

        if (variant.hasOwnProperty('variantDimL') && variant.variantDimL < 0)
            newValidState.variantDimL = false

        if (variant.shippingProduct && variant.variantWeight < 1)
            newValidState.variantWeight.shippingWeightAssigned = false

        let valid = true;


        for (let state in newValidState) {
            if (typeof newValidState[state] === 'object') {
                for (let key in newValidState[state]) {
                    if (newValidState[state][key] === false)
                        valid = false
                }
            }
            else {
                if (newValidState[state] === false)
                    valid = false
            }
        }

        setValidState(newValidState)

        return valid
    }

    async function saveVariant() {
        // if variant does not have a slug, a slug must be generated before posting the variant

        if (await validateVariant() === false) {
            return toast.error('Invalid fields', {
                theme: 'colored',
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

        let variantWithSlug;

        if (!variant.variantSlug) {
            variantWithSlug = {
                ...variant,
                variantSlug: generateSlug(variant.variantName)
            }
        } else {
            variantWithSlug = {
                ...variant,
                variantSlug: generateSlug(variant.variantSlug)
            }
        }

        //set sortOrder for specialValues and specs
        variantWithSlug.specialValues.forEach((specialValue, i) => specialValue.sortOrder = i)
        variantWithSlug.specs.forEach((spec, i) => spec.sortOrder = i)

        let savingVariantToast = toast.loading('Saving variant...', {
            theme: 'light',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });

        OmniApi.post(`/variants/${variant.variantToken}`, variantWithSlug)
            .then(res => {
                setVariant(res.data)
                setUnsavedChanges(false)
                toast.update(savingVariantToast,
                    {
                        render: "Variant saved",
                        type: "success",
                        isLoading: false,
                        autoClose: null,
                        hideProgressBar: null,
                        closeOnClick: null,
                    });
            })
            .catch(err => {
                console.log(err)
                toast.update(savingVariantToast,
                    {
                        render: "Error " + err.response?.status + ": " + err.response?.statusText,
                        type: "error",
                        isLoading: false,
                        autoClose: null,
                        hideProgressBar: null,
                        closeOnClick: null,
                    });
            })
    }

    // this is for the Specs and Special Values DND (drag and drop)

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const handleOnDragEnd = result => {
        const { source, destination } = result;
        // dropped outside the list
        if (!destination) {
            return;
        }

        if (source.droppableId === destination.droppableId) {
            const items = reorder(variant[destination.droppableId], source.index, destination.index);

            setVariant({
                ...variant,
                [destination.droppableId]: items
            })

        } else {
            return
        }
    };

    const addKeyValue = (field) => {
        setUnsavedChanges(true)
        console.log('add')

        if (field === 'specialValue')
            variant.specialValues.push({
                valueName: '',
                value: ''
            })
        if (field === 'spec')
            variant.specs.push({
                specName: '',
                specValue: ''
            })

        setVariant({ ...variant })
    }

    const deleteKeyValue = (field, index) => {
        setUnsavedChanges(true)
        if (field === 'specialValue')
            variant.specialValues.splice(index, 1);
        if (field === 'spec')
            variant.specs.splice(index, 1);

        setVariant({ ...variant })
    }

    const [selectValue, setSelectValue] = useState()

    //this is passed as a prop and creates a copy of the image dnd list state
    const handleListStateChange = (newState) => {

        let newVariant = {
            ...variant
        }

        if (newState.mainImage.length) {
            newVariant.variantImage = {
                file: newState.mainImage[0]?.file,
                name: newState.mainImage[0]?.name,
                type: newState.mainImage[0]?.type ?? ''
            }
        } else {
            newVariant.variantImage = {}
        }

        newVariant.variantImages = []

        newState.variantImages.forEach(e => {
            newVariant.variantImages.push({
                file: e.file,
                name: e.name,
                type: e.type ?? ''
            })
        })

        setVariant(newVariant)
    }

    async function deleteVariant() {

        if (await confirm(`Deleted variants cannot be recovered. Do you want to delete '${variant.variantName}'? `, "Deleting Variant...", "Delete Variant") === false) {
            return
        }

        const deleting = OmniApi.delete(`variants/${variant.variantToken}`)
            .then(res => {
                props.history.push('/omni/catalog/products')
            })
            .catch(err => alert(err))

        toast.promise(
            deleting,
            {
                pending: {
                    render() {
                        return "Deleting variant"
                    },
                    icon: true,
                },
                success: {
                    render(res) {
                        return `Variant "${variant.variantName}" successfully deleted`
                    },
                    // other options
                    icon: true,
                },
                error: {
                    render(err) {
                        // When the promise reject, data will contains the error
                        return `err`
                    },
                    icon: true,
                }
            }
        )

    }

    const truncateString = (string, length) => {
        let slicedString = string.slice(0, length)
        if (string.length > length)
            slicedString = slicedString.concat('...')
        return slicedString
    }

    if (product && variant)
        return (
            <>
                <PageLock unsavedChanges={unsavedChanges} setUnsavedChanges={setUnsavedChanges} />
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Variant Detail | {process.env.REACT_APP_HEADER_TITLE}</title>
                </Helmet>
                <PageTitle
                    breadCrumbItems={[
                        { label: 'Products', path: '/omni/catalog/products' },
                        { label: `${truncateString(variant.prodName, 50)}`, path: `/omni/catalog/product/${product.prodToken}` },
                        { label: variant.variantName ? `${truncateString(variant.variantName, 30)}` : '**no-variant-name**', path: props.location.pathname, active: true },
                    ]}
                    title={'Edit Variant'}
                />
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="variantName">Variant Name</Label>
                                            <Input type="text" name="variantName" id="variantName"
                                                value={variant.variantName}
                                                onChange={(e) => handleInputChange(e)}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="variantSlug">Variant Handle</Label>
                                            <InputGroup>
                                                <Input type="text" name="variantSlug" id="variantSlug"
                                                    value={variant.variantSlug}
                                                    onChange={(e) => handleInputChange(e)}
                                                    disabled={slugDisabled} />
                                                <InputGroupAddon addonType="append">
                                                    <span
                                                        className="btn btn-secondary input-group-text py-0 px-1"
                                                        onClick={() => setSlugDisabled(!slugDisabled)}
                                                    >
                                                        <Icon icon={slugDisabled ? pencilIcon : contentSave} width="25" height="25" />
                                                    </span>
                                                </InputGroupAddon>
                                                <InputGroupAddon addonType="append">
                                                    <span
                                                        className="btn btn-secondary input-group-text py-0 px-1"
                                                        onClick={
                                                            // this is necessary when there isn't a variantSlug
                                                            () => copyToClipboard(variant.variantSlug
                                                                ||
                                                                generateSlug(variant.variantName))}
                                                    >
                                                        <Icon icon={contentCopy} width="25" height="25" id="copy-slug" />
                                                    </span>
                                                    <UncontrolledTooltip placement="top" target="copy-slug" trigger="legacy">
                                                        '{variant.variantSlug}' copied to clipboard
                                                    </UncontrolledTooltip>
                                                </InputGroupAddon>
                                            </InputGroup>
                                            <p className="mt-1 mb-0">
                                                <strong>Full Variant Handle:</strong>{` "/omni/catalog/product/${product.prodSlug || '{product_slug}'}/${variant.variantSlug || '{variant_slug}'}"`}
                                            </p>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label>Variant Description</Label>
                                            <CustomCKEditor
                                                data={variant.variantDescription}
                                                onChange={(data) => {
                                                    setUnsavedChanges(true);
                                                    setVariant({
                                                        ...variant,
                                                        variantDescription: data
                                                    })
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ImagePoolDragAndDrop product={product} variant={variant} handleListStateChange={handleListStateChange} user={user} setUnsavedChanges={setUnsavedChanges} />
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Card>
                            <CardBody>
                                <h4>Pricing</h4>
                                <Row>
                                    <Col md={6}>
                                        <FormGroup row>
                                            <Label for="variantPrice" sm={3}>Price</Label>
                                            <Col sm={9}>
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                                                    <Input type="number" id="variantPrice" name="variantPrice"
                                                        value={variant.variantPrice}
                                                        onChange={(e) => handleInputChange(e)}
                                                    />
                                                </InputGroup>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label for="variantCostPrice" sm={3}>Cost Price</Label>
                                            <Col sm={9}>
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                                                    <Input type="number" id="variantCostPrice" name="variantCostPrice"
                                                        value={variant.variantCostPrice}
                                                        onChange={(e) => handleInputChange(e)}
                                                    />
                                                </InputGroup>
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup row>
                                            <Label for="variantMsrpPrice" sm={3}>MSRP Price</Label>
                                            <Col sm={9}>
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                                                    <Input type="number" id="variantMsrpPrice" name="variantMsrpPrice"
                                                        value={variant.variantMsrpPrice}
                                                        onChange={(e) => handleInputChange(e)}
                                                    />
                                                </InputGroup>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label for="variantPrice" sm={3}>Display Price</Label>
                                            <Col sm={9}>
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                                                    <Input type="number" id="variantDisplayPrice" name="variantDisplayPrice"
                                                        value={variant.variantDisplayPrice}
                                                        onChange={(e) => handleInputChange(e)}
                                                    />
                                                </InputGroup>
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>

                </Row>
                <Row>
                    <Col md={12}>
                        <Card>
                            <CardBody>
                                <div className="d-flex justify-content-between">
                                    <h4>Inventory</h4>
                                    <div className="d-flex align-items-center gap-3">
                                        <div style={{ width: 200 }}>
                                            <Select
                                                id="itemType"
                                                name="shipping"
                                                // style={{ width: 350 }}
                                                className="react-select"
                                                classNamePrefix="react-select"

                                                // value={
                                                //     statusAndTerms?.shippingStatus.map(status => ({ value: status.statusToken, label: status.statusName }))
                                                //         .find(status => status.value === statuses.shippingStatus.statusToken)
                                                // }
                                                // onChange={onSelectChange}
                                                // isSearchable={false}
                                                // hideSelectedOptions
                                                value={selectValue}
                                                onChange={obj => {
                                                    setSelectValue(obj)
                                                    if (obj.value === 'stockBased') {
                                                        setVariant({
                                                            ...variant,
                                                            variantTrackInventory: 1,
                                                            variantCheckInvetory: 1,
                                                            backOrderWarning: 0,
                                                        })
                                                    } else if (obj.value === 'noAlert') {
                                                        setVariant({
                                                            ...variant,
                                                            variantTrackInventory: 1,
                                                            variantCheckInvetory: 0,
                                                            backOrderWarning: 0,
                                                        })
                                                    } else {
                                                        setVariant({
                                                            ...variant,
                                                            variantTrackInventory: 1,
                                                            variantCheckInvetory: 0,
                                                            backOrderWarning: 1,
                                                        })
                                                    }
                                                }}
                                                options={[
                                                    { value: 'stockBased', label: 'Stock based' },
                                                    { value: 'noAlert', label: 'No Store alert' },
                                                    { value: 'withAlert', label: 'Yes Store alert' },
                                                ]}
                                            >
                                            </Select>
                                        </div>
                                        <CustomInput type="checkbox" id="variantCheckInvetory" name="variantCheckInvetory" label="Check Inventory" inline
                                            checked={variant.variantCheckInvetory === 1 ? true : false}
                                            onChange={(e) => {
                                                setSelectValue(null)
                                                setVariant({
                                                    ...variant,
                                                    variantCheckInvetory: e.target.checked ? 1 : 0,
                                                })
                                            }}
                                        >
                                            <Icon icon={informationOutline} style={{ marginLeft: '2px' }} color="#39afd1" id="checkInventory" />
                                            <UncontrolledTooltip placement="top" target="checkInventory">
                                                Check the stock level before allowing variant to be added to cart
                                            </UncontrolledTooltip>
                                        </CustomInput>
                                        <CustomInput type="checkbox" id="variantTrackInventory" name="variantTrackInventory" label="Track Inventory" inline
                                            checked={variant.variantTrackInventory === 1 ? true : false}
                                            onChange={(e) => {
                                                setSelectValue(null)
                                                setVariant({
                                                    ...variant,
                                                    variantTrackInventory: e.target.checked ? 1 : 0
                                                })
                                            }
                                            }
                                        >
                                            <Icon icon={informationOutline} style={{ marginLeft: '2px' }} color="#39afd1" id="trackInventory" />
                                            <UncontrolledTooltip placement="top" target="trackInventory">
                                                Adjust variant inventory when item is purchased/returned
                                            </UncontrolledTooltip>
                                        </CustomInput>
                                        {/* <CustomInput type="checkbox" id="backOrderWarning" name="backOrderWarning" label="BackOrder Warning" inline
                                            checked={variant.backOrderWarning === 1 ? true : false}
                                            onChange={(e) => {
                                                setSelectValue(undefined)
                                                setVariant({
                                                    ...variant,
                                                    backOrderWarning: e.target.checked ? 1 : 0
                                                })
                                            }
                                            }
                                        >
                                            <Icon icon={informationOutline} style={{ marginLeft: '2px' }} color="#39afd1" id="trackInventory" />
                                            <UncontrolledTooltip placement="top" target="trackInventory">
                                                Adjust variant inventory when item is purchased/returned
                                            </UncontrolledTooltip>
                                        </CustomInput> */}
                                        <CustomInput type="checkbox" id="backOrderWarning"
                                            name="backOrderWarning" label="Alert on low" inline
                                            checked={parseInt(variant.backOrderWarning) === 1 ? true : false}
                                            onChange={(e) => {
                                                setSelectValue(null)
                                                setVariant({
                                                    ...variant,
                                                    backOrderWarning: e.target.checked ? '1' : '0'
                                                })
                                            }
                                            }
                                        />
                                    </div>
                                </div>
                                <FormGroup>
                                    <Label for="variantName">SKU<small>{'(Stock Keeping Unit)'}</small>/ Variant Number</Label>
                                    <Input type="text" name="variantNumber" id="variantNumber"
                                        value={variant.variantNumber}
                                        onChange={(e) => handleInputChange(e)}
                                    />
                                </FormGroup>
                                <Row>
                                    <Col sm={6}>
                                        <FormGroup>
                                            <Label for="inventoryCount">Item Quantity</Label>
                                            <Input type="number" name="inventoryCount" id="inventoryCount"
                                                className={`${!validState.inventoryCount ? 'invalid' : ''}`}
                                                value={variant.inventoryCount}
                                                onChange={(e) => handleInputChange(e)}
                                            />
                                            {!validState.inventoryCount ? <div className="invalid-text">Number cannot contain a decimal</div> : ''}
                                        </FormGroup>
                                    </Col>
                                    <Col sm={6}>
                                        <FormGroup>
                                            <Label for="variantLowlevel">Item Low Level</Label>
                                            <Input type="number" name="variantLowlevel" id="variantLowlevel"
                                                className={`${!validState.variantLowlevel ? 'invalid' : ''}`}
                                                value={variant.variantLowlevel}
                                                onChange={(e) => handleInputChange(e)}
                                            />
                                            {!validState.variantLowlevel ? <div className="invalid-text">Number cannot contain a decimal</div> : ''}
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md="6">
                        <Card>
                            <CardBody>
                                <h3>Special Values</h3>
                                <DragDropContext onDragEnd={handleOnDragEnd}>
                                    <Droppable className="d-flex" droppableId='specialValues'>
                                        {(provided) => (
                                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                                {variant.specialValues.map((specialValue, i) => (
                                                    <Draggable key={`${i}`} draggableId={`${i}`} index={i} className="d-flex">
                                                        {(provided) => (<div ref={provided.innerRef} {...provided.draggableProps} className="mb-1">
                                                            <InputGroup>
                                                                <InputGroupAddon {...provided.dragHandleProps} addonType="prepend">
                                                                    <span className="input-group-text py-0 px-1">
                                                                        <Icon icon={dragIcon} width="25" height="25" />
                                                                    </span>
                                                                </InputGroupAddon>
                                                                <Input
                                                                    placeholder="Key"
                                                                    name="valueName"
                                                                    value={specialValue.valueName}
                                                                    onChange={(e) => handleSpecialValueChange(e, i)}
                                                                />
                                                                <Input
                                                                    placeholder="Value"
                                                                    name="value"
                                                                    value={specialValue.value}
                                                                    onChange={(e) => handleSpecialValueChange(e, i)}
                                                                />
                                                                <InputGroupAddon addonType="append">
                                                                    <span className="btn input-group-text py-0 px-1"
                                                                        onClick={() => deleteKeyValue('specialValue', i)}
                                                                    >
                                                                        <Icon icon={trashCanOutline} width="25" height="25" />
                                                                    </span>
                                                                </InputGroupAddon>
                                                            </InputGroup>
                                                        </div>)}</Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                                <Row>
                                    <Col className="d-flex">
                                        <div className="btn" onClick={() => addKeyValue('specialValue')}><strong>+ Add Special Value...</strong></div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="6">
                        <Card>
                            <CardBody>
                                <h3>Specs</h3>
                                <DragDropContext onDragEnd={handleOnDragEnd}>
                                    <Droppable className="d-flex" droppableId='specs'>
                                        {(provided) => (
                                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                                {variant.specs?.map((spec, i) => (
                                                    <Draggable key={`${i}`} draggableId={`${i}`} index={i} className="d-flex">
                                                        {(provided) => (<div ref={provided.innerRef} {...provided.draggableProps} className="mb-1">
                                                            <InputGroup>
                                                                <InputGroupAddon {...provided.dragHandleProps} addonType="prepend">
                                                                    <span className="input-group-text py-0 px-1">
                                                                        <Icon icon={dragIcon} width="25" height="25" />
                                                                    </span>
                                                                </InputGroupAddon>
                                                                <Input
                                                                    placeholder="Key"
                                                                    name="specName"
                                                                    value={spec.specName}
                                                                    onChange={(e) => handleSpecChange(e, i)}
                                                                />
                                                                <Input
                                                                    placeholder="Value"
                                                                    name="specValue"
                                                                    value={spec.specValue}
                                                                    onChange={(e) => handleSpecChange(e, i)}
                                                                />
                                                                <InputGroupAddon addonType="append">
                                                                    <span className="btn input-group-text py-0 px-1"
                                                                        onClick={() => deleteKeyValue('spec', i)}
                                                                    >
                                                                        <Icon icon={trashCanOutline} width="25" height="25" />
                                                                    </span>
                                                                </InputGroupAddon>
                                                            </InputGroup>
                                                        </div>)}</Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                                <Row>
                                    <Col className="d-flex">
                                        <div className="btn" onClick={() => addKeyValue('spec')}><strong>+ Add Spec...</strong></div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <h4>Shipping Info</h4>
                                <Row className="mt-1 mb-2">
                                    <Col>
                                        <CustomInput type="checkbox" id="shippingProduct" name="shippingProduct" label="Shipping Required" inline
                                            checked={variant.shippingProduct === 1 ? true : false}
                                            onChange={(e) => {
                                                if (!e.target.checked)
                                                    setValidState({
                                                        ...validState,
                                                        variantWeight: {
                                                            ...validState.variantWeight,
                                                            shippingWeightAssigned: true
                                                        }
                                                    })
                                                else {
                                                    if (variant.variantWeight < 1) {
                                                        setValidState({
                                                            ...validState,
                                                            variantWeight: {
                                                                ...validState.variantWeight,
                                                                shippingWeightAssigned: false
                                                            }
                                                        })
                                                    }
                                                }
                                                setVariant({
                                                    ...variant,
                                                    shippingProduct: e.target.checked ? 1 : 0
                                                })
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={3}>
                                        <Label for="variantWeight">Weight<small>{'(lbs)'}</small></Label>
                                        <Input type="number" id="variantWeight" name="variantWeight" placeholder="0"
                                            min="0"
                                            className={`${(!validState.variantWeight.notNegative || !validState.variantWeight.shippingWeightAssigned) ? 'invalid' : ''}`}
                                            value={variant.variantWeight}
                                            onChange={(e) => handleInputChange(e)}
                                        />
                                        {!validState.variantWeight.notNegative ? <div className="invalid-text">Value must be greater than or equal to 0</div> : ''}
                                        {!validState.variantWeight.shippingWeightAssigned ? <div className="invalid-text">Shipped items must have an assigned weight greater than 0</div> : ''}
                                    </Col>
                                    <Col sm={3}>
                                        <Label for="variantDimW">Width<small>{'(in)'}</small></Label>
                                        <Input type="number" id="variantDimW" name="variantDimW" placeholder="0"
                                            min="0"
                                            className={`${!validState.variantDimW ? 'invalid' : ''}`}
                                            value={variant.variantDimW}
                                            onChange={(e) => handleInputChange(e)}
                                        />
                                        {!validState.variantDimW ? <div className="invalid-text">Value must be greater than or equal to 0</div> : ''}
                                    </Col>
                                    <Col sm={3}>
                                        <Label for="variantDimH">Height<small>{'(in)'}</small></Label>
                                        <Input type="number" id="variantDimH" name="variantDimH" placeholder="0"
                                            min="0"
                                            className={`${!validState.variantDimH ? 'invalid' : ''}`}
                                            value={variant.variantDimH}
                                            onChange={(e) => handleInputChange(e)}
                                        />
                                        {!validState.variantDimH ? <div className="invalid-text">Value must be greater than or equal to 0</div> : ''}
                                    </Col>
                                    <Col sm={3}>
                                        <Label for="variantDimL">Length<small>{'(in)'}</small></Label>
                                        <Input type="number" id="variantDimL" name="variantDimL" placeholder="0"
                                            min="0"
                                            className={`${!validState.variantDimL ? 'invalid' : ''}`}
                                            value={variant.variantDimL}
                                            onChange={(e) => handleInputChange(e)}
                                        />
                                        {!validState.variantDimL ? <div className="invalid-text">Value must be greater than or equal to 0</div> : ''}
                                    </Col>

                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col className="d-flex justify-content-end">
                        <Button color="danger" size="lg" onClick={deleteVariant}>Delete</Button>
                        <Button color="primary" size="lg" className="ml-3" onClick={saveVariant}>Save Variant</Button>
                    </Col>
                </Row>
            </>
        )
    else
        return (
            <div className='page-loading-loader-div w-100 d-flex justify-content-center align-items-center'>
                <div>
                    <h4>Loading variant...</h4>
                    <div className="bouncing-loader">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        )
}

// image dnd component


const ImagePoolDragAndDrop = props => {

    const { product, variant, user } = props;

    const imageUploader = React.useRef(null);

    const [listState, setListState] = useState({
        mainImage: [],
        variantImages: [],
        imagePool: []
    })

    useEffect(() => {
        props.handleListStateChange(listState)
    }, [listState])

    // created fullImagePool to store all of the images that are part of the pool even if they aren't being shown because they are also on the variant
    const [fullImagePool, setFullImagePool] = useState([])



    useEffect(() => {

        if (product && variant) {

            //tempImagePool stores all imagePool imgs before they are set to a Set
            let tempImagePool = [];

            if (product) {
                if (product.prodImage.file && typeof product.prodImage.file === 'string' && product.prodImage.file !== "[]")
                    tempImagePool.push(product.prodImage)
                tempImagePool.push(...product.prodImages)
                product.variants.filter(vrnt => vrnt.variantToken !== variant.variantToken)
                    .forEach(vrnt => {
                        tempImagePool.push(...vrnt.variantImages)
                        if (vrnt.hasOwnProperty('variantImage')) {
                            if (vrnt.variantImage.file && typeof vrnt.variantImage.file === 'string' && vrnt.variantImage.file !== "[]") {
                                tempImagePool.push(vrnt.variantImage)
                            }
                        }
                    })

                // id's and statuses are deleted in order for the objects to only have a file property, so when 2 are alike they will be filtered out with Set ???
                tempImagePool.forEach((img, i) => {
                    delete img.id
                    delete img.status
                })

                // filter out all duplicate imgs
                tempImagePool = tempImagePool.filter((v, i, a) => a.findIndex(t => (t.file === v.file)) === i)

                // assign a new id and status once all imgs are unique
                tempImagePool.forEach((image, i) => {
                    image.id = Math.random().toString(36).slice(-8);
                    image.status = 'poolImages';
                })
            }

            if (variant.variantImage?.file && typeof variant.variantImage.file === 'string' && variant.variantImage.file !== "[]") {
                variant.variantImage.id = Math.random().toString(36).slice(-8);
                variant.variantImage.status = 'mainImage';
            } else {
                variant.variantImage = ''
            }

            if (variant.variantImages.length) {
                variant.variantImages.forEach((image) => {
                    image.id = Math.random().toString(36).slice(-8);
                    image.status = 'variantImages'
                })
            }

            let newImagePool = [];

            // all imgs in the image pool are compared to the images in the variant pool and all non duplicates are pushed to a new array

            tempImagePool.forEach((e) => {
                if (e.file === variant.variantImage?.file) {
                    return
                }

                for (let img of variant.variantImages) {
                    if (e.file === img.file) {
                        return
                    }
                }
                newImagePool.push(e)
            })

            // send all unique images to the full image pool
            setFullImagePool(tempImagePool)

            setListState({
                mainImage: variant.variantImage ? [variant.variantImage] : [],
                variantImages: [...variant.variantImages],
                // imagePool in the DND component is assigned the value of the new array
                imagePool: [...newImagePool]
            })


        }

    }, [])

    const getList = id => listState[id];

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const move = (source, destination, droppableSource, droppableDestination) => {
        const sourceClone = Array.from(source);
        const destClone = Array.from(destination);
        const [removed] = sourceClone.splice(droppableSource.index, 1);
        removed.status = droppableDestination.droppableId;
        destClone.splice(droppableDestination.index, 0, removed);
        const result = {};
        result[droppableSource.droppableId] = sourceClone;
        result[droppableDestination.droppableId] = destClone;

        return result;
    };

    // when imgs are dragged from the ImagePool component the images are merely copied and placed in the new component but are not reomved from the imagePool
    const add = (source, destination, droppableSource, droppableDestination) => {
        const sourceClone = Array.from(source);
        const destClone = Array.from(destination);
        const [copied] = sourceClone.slice(droppableSource.index, droppableSource.index + 1);
        copied.status = droppableDestination.droppableId;
        destClone.splice(droppableDestination.index, 0, copied);

        let newImagePool = [];

        fullImagePool.forEach((e) => {

            if (e.file === listState['mainImage'][0]?.file)
                return

            for (let img of listState['variantImages']) {
                if (e.file === img.file) {
                    return
                }
            }

            for (let img of destClone) {
                if (e.file === img.file) {
                    return
                }
            }
            newImagePool.push(e)
        })

        const result = {};
        result[droppableSource.droppableId] = newImagePool;
        result[droppableDestination.droppableId] = destClone;

        return result;
    };

    const handleOnDragEnd = result => {
        const { source, destination } = result;
        // dropped outside the list
        if (!destination) {
            return;
        }
        if (source.droppableId === destination.droppableId) {
            const items = reorder(getList(source.droppableId), source.index, destination.index);

            let state = { ...listState };
            state[source.droppableId] = items;
            setListState(state);
            props.setUnsavedChanges(true)
        } else {
            if (source.droppableId === 'imagePool') {
                const result = add(
                    getList(source.droppableId),
                    getList(destination.droppableId),
                    source,
                    destination
                );
                const state = { ...listState, ...result };
                setListState(state);
                props.setUnsavedChanges(true)
            } else {
                const result = move(
                    getList(source.droppableId),
                    getList(destination.droppableId),
                    source,
                    destination
                );
                const state = { ...listState, ...result };
                setListState(state);
                props.setUnsavedChanges(true)
            }
        }
    };


    const handleMultiImageUpload = (e) => {
        let images = e.target.files;

        const uploadImage = (img) => {

            let payload = img;

            return axios.post('https://fs1.cloudsnob.com/upload', payload, {
                headers: {
                    'upload_token': user.company.fs1Token
                }
            })
                .then(res => {
                    let resImage = {};
                    resImage.file = res.data.path;
                    resImage.id = `${Math.random().toString(36).slice(-8)}`
                    resImage.status = 'variantImages';

                    return resImage;
                })
        }

        let imagesArray = []

        for (let i = 0; i < images.length; i++) {
            let img = new FormData();
            img.append('ev_file', images[i]);

            imagesArray.push(img)
        }

        let promiseArray = Array.from(imagesArray).map(i => uploadImage(i));

        Promise.all(promiseArray)
            .then(res => {
                props.setUnsavedChanges(true)
                setListState({
                    ...listState,
                    variantImages: [...listState.variantImages, ...res]
                })
            })
            .catch(err => alert(err))
    }

    const deleteImage = async (status, id, fileName) => {
        if (status === 'mainImage') {
            if (await confirm("Would you like to delete the Main Image?", "Deleting Main Image...", "Delete") === false) {
                return
            }
        }

        //check if image pending delete exits in image pool
        const newImage = fullImagePool.find(e => e.file === fileName)
        //if image in not in imagepool it will be removed from the product, so admin must confirm that action
        if (newImage === undefined) {
            if (await confirm("The image will be completely removed from this product. Would you like to proceed?", "Removing Image", "Confirm") === false) {
                return
            }
        }

        let newMainImage = listState.mainImage.filter(image => image.id !== id)

        // when images are deleted from the variant, if the images still exist within the product they are added to the image Pool component
        let newVariantImages = listState.variantImages.filter(image => image.id !== id)

        let newImagePool = [];

        // all image pool images are compared to the remaining images and if they are not duplicates then they are added back to the Image Pool component
        fullImagePool.forEach((e) => {
            if (e.file === newMainImage[0]?.file)
                return

            for (let img of newVariantImages) {
                if (e.file === img.file) {
                    return
                }
            }
            newImagePool.push(e)
        })

        setListState({
            mainImage: newMainImage,
            variantImages: newVariantImages,
            imagePool: newImagePool
        });
        props.setUnsavedChanges(true)
    }

    const handleOnDragStart = start => {
        // console.log(start)
    }

    const handleOnDragUpdate = update => {
        // console.log(update)
        // const {destination} = update;
        // if (destination.droppableId === 'mainImage'){
        //     if (listState.mainImage.length) return;
        // }

    }

    function resolveYoutubeVideo(value) {
        let type;
        if (value.startsWith("https://youtu.be/")) { // short link
            value = value.substring(17);
            type = "youtube";
        } else if (value.startsWith("https://www.youtube.com/watch?v=")) { // regular url
            let listKeyIndex = value.indexOf("&list");
            if (listKeyIndex < 0) value = value.substring(32);
            else value = value.substring(32, listKeyIndex);
            type = "youtube";
        } else if (value.startsWith("https://www.youtube.com/embed/")) { // embed link
            value = value.substring(30);
            type = "youtube";
        } else if (value.search("https://www.youtube.com/embed/")) {  // iframe with embed link
            let fromIndex = value.search("https://www.youtube.com/embed/");
            fromIndex += 30;
            let endIndex = value.indexOf('"', fromIndex);
            value = value.substring(fromIndex, endIndex);
            type = "youtube";
        }
        return {
            file: value,
            type,
            id: Math.random().toString(36).slice(-8)
        }
    }

    const [addVideoModal, setAddVideoModal] = useState(false);

    const toggleAddVideoModal = () => {
        setAddVideoModal(!addVideoModal)
    }

    const saveYoutubeVideo = (link) => {

        const youtubeRegex = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/

        if (!youtubeRegex.test(link))
            return alert('not a valid youtube link')

        let newVideo = resolveYoutubeVideo(link)

        setListState({
            ...listState,
            variantImages: [...listState.variantImages, newVideo]
        })

        props.setUnsavedChanges(true)

        toggleAddVideoModal()
    }

    const uploadImage = (img) => {
        let payload = new FormData();

        payload.append('ev_file', img);

        axios.post('https://fs1.cloudsnob.com/upload', payload, {
            headers: {
                'upload_token': user.company.fs1Token
            }
        })
            .then(res => {
                let mainImage = {
                    file: res.data.path,
                    id: Math.random().toString(36).slice(-8),
                    status: "mainImage"
                }
                setListState({ ...listState, mainImage: [mainImage] })
                props.setUnsavedChanges(true)
            })
    }

    const handleSingleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            uploadImage(file);
        }
        else return
    }

    // function handleOnDragEnd(result) {
    //     console.log('dropresult', result)
    //     if (!result.destination) return;

    //     const items = Array.from(list);
    //     const [reorderedItem] = items.splice(result.source.index, 1);
    //     items.splice(result.destination.index, 0, reorderedItem);

    //     setList(items);
    // }
    return (
        <DragDropContext onDragStart={handleOnDragStart} onDragEnd={handleOnDragEnd} onDragUpdate={handleOnDragUpdate}>
            <Row className="mt-3">
                <Col>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col className="d-flex justify-content-between">
                                    <h3>Images</h3>
                                    <div className="button-list d-flex justify-items-center" >
                                        <Button color='primary' onClick={toggleAddVideoModal}>Upload Video</Button>
                                        <AddVideoModal modal={addVideoModal} toggle={toggleAddVideoModal} saveVideo={saveYoutubeVideo} />
                                        <Button color='primary' onClick={() => imageUploader.current.click()}>Upload Images</Button>
                                        <input
                                            type="file"
                                            accept="image/*"
                                            onChange={(e) => {
                                                handleMultiImageUpload(e)
                                            }}
                                            multiple
                                            ref={imageUploader}
                                            style={{
                                                display: "none"
                                            }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row className="flex-nowrap">
                                <Col xs="auto">
                                    <h4>Main Image</h4>
                                    <Droppable droppableId="mainImage" direction="horizontal" isDropDisabled={listState.mainImage.length ? true : false}>
                                        {(provided, snapshot) => (
                                            <Card className="border overflow-auto">
                                                <CardBody>
                                                    <div {...provided.droppableProps} ref={provided.innerRef} className="d-flex">
                                                        {listState['mainImage'].length ? listState['mainImage'].map((item, i) => (
                                                            <ImageUpload
                                                                key={item.id}
                                                                id={item.id}
                                                                status={item.status}
                                                                image={item.file}
                                                                type={item.type ?? false}
                                                                deleteImage={deleteImage}
                                                                allowDelete={true}
                                                                imageSize={100}
                                                            />
                                                        ))
                                                            :
                                                            <ImageUpload
                                                                small={true}
                                                                handleImageUpload={handleSingleImageUpload}
                                                                deleteImage={deleteImage}
                                                                allowDelete={true}
                                                                imageSize={100}
                                                            />
                                                        }
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        )}
                                    </Droppable>
                                </Col>
                                <Col className="overflow-hidden">
                                    <h4>Additional Images</h4>
                                    <Droppable droppableId="variantImages" direction="horizontal">
                                        {(provided) => (
                                            <Card className="border overflow-auto">
                                                <CardBody>
                                                    <div {...provided.droppableProps} ref={provided.innerRef} className="d-flex">
                                                        {listState['variantImages'].length ? listState['variantImages'].map((item, i) => (
                                                            <Draggable key={item.id} draggableId={item.id} index={i}>{(provided, snapshot) => (<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="mr-2">
                                                                <ImageUpload
                                                                    key={item.id}
                                                                    id={item.id}
                                                                    status={item.status}
                                                                    type={item.type ?? false}
                                                                    image={item.file}
                                                                    deleteImage={deleteImage}
                                                                    allowDelete={true}
                                                                    imageSize={100}
                                                                    noHover={snapshot.isDragging}
                                                                />
                                                            </div>)}</Draggable>
                                                        ))
                                                            :
                                                            <div className="d-flex justify-content-center align-items-center"
                                                                style={{ height: '100px', width: '100%', border: '2px dashed #E0E7ED', borderRadius: '0.25rem', fontSize: '22px', color: '#E0E7ED' }}
                                                            >
                                                                <strong>No Images</strong>
                                                            </div>
                                                        }
                                                        {listState['variantImages'].length ? provided.placeholder : ''}
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        )}
                                    </Droppable>
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col>
                                    <h4>Image Pool<small>{" (all other images associated with this product)"}</small></h4>
                                    <Droppable droppableId="imagePool" direction="horizontal" isDropDisabled={true}>
                                        {(provided) => (
                                            <Card className="border overflow-auto">
                                                <CardBody>
                                                    <div {...provided.droppableProps} ref={provided.innerRef} className="d-flex">
                                                        {listState['imagePool'].length ? listState['imagePool'].map((item, i) => (
                                                            <Draggable key={item.id} draggableId={item.id} index={i}>{(provided, snapshot) => (<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="mr-2">
                                                                <ImageUpload
                                                                    key={item.id}
                                                                    id={item.id}
                                                                    status={item.status}
                                                                    type={item.type ?? false}
                                                                    image={item.file}
                                                                    deleteImage={deleteImage}
                                                                    allowDelete={false}
                                                                    imageSize={100}
                                                                    noHover={snapshot.isDragging}
                                                                />
                                                            </div>)}</Draggable>
                                                        ))
                                                            :
                                                            <div className="d-flex justify-content-center align-items-center"
                                                                style={{ height: '100px', width: '100%', border: '2px dashed #E0E7ED', borderRadius: '0.25rem', fontSize: '22px', color: '#E0E7ED' }}>
                                                                <strong>No Images</strong>
                                                            </div>
                                                        }
                                                        {listState['imagePool'].length ? provided.placeholder : ''}
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        )}
                                    </Droppable>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </DragDropContext>
    )
}

const AddVideoModal = props => {

    const [videoLink, setVideoLink] = useState('');

    const [validLink, setValidLink] = useState(true)

    const { modal, toggle } = props;

    useEffect(() => {

        const youtubeRegex = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/

        videoLink ? setValidLink(youtubeRegex.test(videoLink)) : setValidLink(true)

    }, [videoLink])

    return (
        <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                Add Video
            </ModalHeader>
            <ModalBody>
                <FormGroup>
                    <Label for="videoLink">Add Youtube Link</Label>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <span className="input-group-text py-0 px-1">
                                <Icon icon={youtubeIcon} width="25px" />
                            </span>
                        </InputGroupAddon>
                        <Input type="text" name="videoLink" id="videoLink"
                            value={videoLink}
                            onChange={(e) => setVideoLink(e.target.value)}
                            placeholder="https://www.youtube.com/..."
                        />
                    </InputGroup>
                    {!validLink && <div className="text-danger mt-1">Invalid Youtube Link</div>}
                </FormGroup>
                {(validLink && videoLink) &&
                    <ReactPlayer
                        width="100%"
                        url={videoLink}
                        controls={true}
                    />
                }
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={() => props.saveVideo(videoLink)} disabled={!videoLink || !validLink}>
                    Save Video
                </Button>{' '}
                <Button color="secondary" onClick={toggle}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default VariantDetail;