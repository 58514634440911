import React, { useState, useEffect } from 'react';
import { Switch, Route } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';
import { Card, CardBody, Badge, Container, ListGroup, ListGroupItem, Row, Col, FormGroup, Input, Label, Nav, NavItem, NavLink, CustomInput, InputGroup, InputGroupAddon, Button, Table, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from 'reactstrap';
import { Icon } from '@iconify/react';
import truckIcon from '@iconify/icons-uil/truck';
import storeIcon from '@iconify/icons-uil/store';
import fileAlt from '@iconify/icons-uil/file-alt';
import { confirm } from "./Confirm";
import deleteCircle from '@iconify/icons-mdi/delete-circle';
import deleteIcon from '@iconify/icons-mdi/delete';
import cloudDataConnection from '@iconify/icons-uil/cloud-data-connection';
import percentageIcon from '@iconify/icons-uil/percentage';
import classnames from 'classnames';
import upsIcon from '@iconify/icons-la/ups';
import fedexIcon from '@iconify/icons-la/fedex';
import uspsIcon from '@iconify/icons-la/usps';
import { CountryList } from '../data/CountriesList';
import { StateList } from '../data/StateList';
import { Typeahead } from 'react-bootstrap-typeahead';
import Select from 'react-select';
import { OmniApi } from '../customAPI/OmniApi';
import truckLoading from '@iconify/icons-uil/truck-loading';
import truckIcon2 from '@iconify/icons-la/truck';
import arrowTopRightBoldBoxOutline from '@iconify/icons-mdi/arrow-top-right-bold-box-outline';
import handshakeIcon from '@iconify/icons-la/handshake';
import Skeleton from 'react-loading-skeleton';
import SimpleBar from 'simplebar-react';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallbackModal from '../utility-components/ErrorFallbackModal';
import { AvField, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import windowIcon from '@iconify/icons-uil/window';
import eyeIcon from '@iconify/icons-mdi/eye';
import informationOutline from '@iconify/icons-mdi/information-outline';
import PageLock from '../utility-components/PageLock';

const Settings = props => {

    const [activeTab, setActiveTab] = useState('store')

    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        // navigate to /settings/store by default 
        if (location.pathname === '/omni/settings')
            history.replace('/omni/settings/store')
    }, [history, location.pathname])

    const toggle = (tab) => {

        if (activeTab !== tab) {
            setActiveTab(tab);
        }

    }

    const navigateSettings = (e, category) => {
        e.preventDefault()
        history.push('/omni/settings/' + category)
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Settings | {process.env.REACT_APP_HEADER_TITLE}</title>
            </Helmet>
            <Card style={{ height: '75vh' }} className="mt-2">
                <Row className="h-100 d-flex flex-nowrap">
                    <Col sm={'auto'} className="pr-0 h-100">
                        <Card style={{ minWidth: '300px', width: '300px' }} className="mb-0 shadow h-100">
                            <ListGroup>
                                <ListGroupItem tag="a" href="#" action
                                    className={classnames({ active: activeTab === 'store' })}
                                    onClick={(e) => navigateSettings(e, 'store')}
                                >
                                    <div className="w-100 h-100 d-flex">
                                        <div className="h-100">
                                            <Icon icon={storeIcon} color={activeTab === 'store' ? '#f8f9fa' : "#A2A2A2"} width="22" height="22" />
                                        </div>
                                        <div className="ml-1">
                                            <div><strong>Store</strong></div>
                                            <small className={activeTab === 'store' ? 'text-light' : "text-muted"}>View and update your store details</small>
                                        </div>
                                    </div>
                                </ListGroupItem>
                                <ListGroupItem tag="a" href="#" action
                                    className={classnames({ active: activeTab === 'site' })}
                                    onClick={(e) => navigateSettings(e, 'site')}
                                >
                                    <div className="w-100 h-100 d-flex">
                                        <div className="h-100">
                                            <Icon icon={windowIcon} color={activeTab === 'site' ? '#f8f9fa' : "#A2A2A2"} width="22" height="22" />
                                        </div>
                                        <div className="ml-1">
                                            <div><strong>Site</strong></div>
                                            <small className={activeTab === 'site' ? 'text-light' : "text-muted"}>View and set your site settings</small>
                                        </div>
                                    </div>
                                </ListGroupItem>
                                <ListGroupItem tag="a" href="#" action
                                    className={classnames({ active: activeTab === 'shipping' })}
                                    onClick={(e) => navigateSettings(e, 'shipping/ups')}
                                >
                                    <div className="w-100 h-100 d-flex">
                                        <div className="h-100">
                                            <Icon icon={truckIcon} color={activeTab === 'shipping' ? '#f8f9fa' : "#A2A2A2"} width="22" height="22" hFlip={true} />
                                        </div>
                                        <div className="ml-1">
                                            <div><strong>Shipping</strong></div>
                                            <small className={activeTab === 'shipping' ? 'text-light' : "text-muted"}>Manage how orders are shipped</small>
                                        </div>
                                    </div>
                                </ListGroupItem>
                                <ListGroupItem tag="a" href="#" action
                                    className={classnames({ active: activeTab === 'files' })}
                                    onClick={(e) => navigateSettings(e, 'files')}
                                >
                                    <div className="w-100 h-100 d-flex">
                                        <div className="h-100">
                                            <Icon icon={fileAlt} color={activeTab === 'files' ? '#f8f9fa' : "#A2A2A2"} width="22" height="22" />
                                        </div>
                                        <div className="ml-1">
                                            <div><strong>Files</strong></div>
                                            <small className={activeTab === 'files' ? 'text-light' : "text-muted"}>View and manage your files</small>
                                        </div>
                                    </div>
                                </ListGroupItem>
                                <ListGroupItem tag="a" href="#" action
                                    className={classnames({ active: activeTab === 'webhooks' })}
                                    onClick={(e) => navigateSettings(e, 'webhooks')}
                                >
                                    <div className="w-100 h-100 d-flex">
                                        <div className="h-100">
                                            <Icon icon={cloudDataConnection} color={activeTab === 'webhooks' ? '#f8f9fa' : "#A2A2A2"} width="22" height="22" />
                                        </div>
                                        <div className="ml-1">
                                            <div><strong>Webhooks</strong></div>
                                            <small className={activeTab === 'webhooks' ? 'text-light' : "text-muted"}>Keep your application in sync</small>
                                        </div>
                                    </div>
                                </ListGroupItem>
                                <ListGroupItem tag="a" href="#" action
                                    className={classnames({ active: activeTab === 'tax' })}
                                    onClick={(e) => navigateSettings(e, 'tax')}
                                >
                                    <div className="w-100 h-100 d-flex">
                                        <div className="h-100">
                                            <Icon icon={percentageIcon} color={activeTab === 'tax' ? '#f8f9fa' : "#A2A2A2"} width="22" height="22" />
                                        </div>
                                        <div className="ml-1">
                                            <div><strong>Tax</strong></div>
                                            <small className={activeTab === 'tax' ? 'text-light' : "text-muted"}>Set your store tax settings</small>
                                        </div>
                                    </div>
                                </ListGroupItem>
                            </ListGroup>
                        </Card>
                    </Col>
                    <Col style={{ flex: 1 }} className="mt-5 ml-4 mr-5 mb-3">
                        <Card className="shadow h-100 w-100" style={{ borderRadius: '14px' }}>
                            <Switch>
                                <Route path='/omni/settings/store'>
                                    <Store toggle={toggle} />
                                </Route>
                                <Route path='/omni/settings/site'>
                                    <SiteSettings toggle={toggle} />
                                </Route>
                                <Route path='/omni/settings/shipping'>
                                    <Shipping toggle={toggle} />
                                </Route>
                                <Route path='/omni/settings/files'>
                                    <Files toggle={toggle} />
                                </Route>
                                <Route path='/omni/settings/webhooks'>
                                    <Webhooks toggle={toggle} />
                                </Route>
                                <Route path='/omni/settings/tax'>
                                    <Tax toggle={toggle} />
                                </Route>
                            </Switch>
                        </Card>
                    </Col>
                </Row>
            </Card>
        </>
    )
}

const Store = props => {

    const { toggle } = props;

    const [data, setData] = useState()
    useEffect(() => {
        toggle('store')
    }, [toggle])

    const onSave = () => {
        OmniApi.post('/sites', data)
            .then(({ data }) => {
                setData(undefined)
            })
            .catch(err => console.error(err))
    }

    return (
        <>
            <Container style={{ width: '80%', overflow: 'auto' }} className="mt-2 mb-2">
                <h3>Edit Store Address</h3>
                <Row>
                    <Col sm={12}>
                        <FormGroup>
                            <Label for="address" >Address</Label>
                            <Input placeholder="Enter address..." name="address"
                                value={data?.address}
                                onChange={(e) => setData({
                                    ...data,
                                    address: e.target.value
                                })}
                            />
                        </FormGroup>
                    </Col>
                    <Col sm={12}>
                        <FormGroup>
                            <Label for="" >Apartment, Suite, etc. <small>(optional)</small></Label>
                            <Input placeholder="" name=""
                            // onChange={(e) => setData({
                            //     ...data,
                            //     address: e.target.value
                            // })}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="city" >City</Label>
                            <Input placeholder="Enter city..." name="city"
                                value={data?.city}
                                onChange={(e) => setData({
                                    ...data,
                                    city: e.target.value
                                })}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="state" >State</Label>
                            <Input placeholder="Enter state..." name="state"
                                value={data?.state}
                                onChange={(e) => setData({
                                    ...data,
                                    state: e.target.value
                                })}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="country" >Country</Label>
                            <Input placeholder="Enter country..." name="country"
                                value={data?.country}
                                onChange={(e) => setData({
                                    ...data,
                                    country: e.target.value
                                })}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="zip" >ZIP</Label>
                            <Input placeholder="Enter zipcode..." name="zip"
                                value={data?.zip}
                                onChange={(e) => setData({
                                    ...data,
                                    zip: e.target.value
                                })}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={12}>
                        <FormGroup>
                            <Button color="primary" className="card-action px-3 py-2 float-right" onClick={onSave}>
                                Save
                            </Button>
                        </FormGroup>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

const Shipping = props => {

    const { toggle } = props;

    useEffect(() => {
        toggle('shipping')
    }, [toggle])

    const [activeTab, setActiveTab] = useState('ups')

    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        // navigate to /settings/store/shipping/ups by default 
        if (location.pathname === '/omni/settings/shipping')
            history.push('/omni/settings/shipping/ups')
    }, [history, location.pathname])

    useEffect(() => {

    }, [location])


    const toggleShipping = (tab) => {

        if (activeTab !== tab) {
            setActiveTab(tab);
        }

    }

    const navigateSettings = (e, category) => {
        e.preventDefault()
        if (activeTab !== category)
            history.push('/omni/settings/shipping/' + category)
    }

    const onCountrySelectChange = (selection) => {
        // setCoupon({
        //     ...coupon,
        //     locationsEligible: selection.map(s => s.code)
        // })
    }

    const [shippingMethodsFull, setShippingMethodsFull] = useState([])

    useEffect(() => {
        OmniApi.get('/shippingmethods/methodsfull')
            .then(res => setShippingMethodsFull(res.data))
    }, [])

    const reloadShippingMethods = () => {
        OmniApi.get('/shippingmethods/methodsfull')
            .then(res => setShippingMethodsFull(res.data))
    }

    const getShippingMethodIcon = (id) => {
        if (id === 'ups')
            return upsIcon
        if (id === 'fedex')
            return fedexIcon
        if (id === 'usps')
            return uspsIcon
        if (id === 'freeshipping')
            return truckIcon2
        if (id === 'storepickup')
            return truckLoading
        if (id === 'handdelivered')
            return handshakeIcon
    }

    return (
        <div className="h-100 d-flex flex-nowrap">
            <div className="pr-0 h-100">
                <Card style={{ minWidth: '150px', width: '230px' }} className="mb-0 h-100">
                    <ListGroup>
                        {shippingMethodsFull.map(method => (
                            <ListGroupItem tag="a" href="#" action
                                className={classnames({ active: activeTab === method.methodId })}
                                onClick={(e) => navigateSettings(e, method.methodId)}
                            >
                                <div className="w-100 h-100 d-flex">
                                    <div className="h-100">
                                        <Icon icon={getShippingMethodIcon(method.methodId)} color={activeTab === method.methodId ? '#f8f9fa' : "#A2A2A2"} width="22" height="22" />
                                    </div>
                                    <div className="ml-1">
                                        <div><strong>{method.methodName}</strong></div>
                                    </div>
                                    <div className="ml-auto">
                                        {method.active ?
                                            <Badge color={'success-lighten'}>
                                                active
                                            </Badge>
                                            :
                                            <Badge color={'danger-lighten'} >
                                                inactive
                                            </Badge>
                                        }
                                    </div>
                                </div>
                            </ListGroupItem>
                        ))}
                    </ListGroup>
                </Card>
            </div>
            <Col>
                <SimpleBar style={{ maxHeight: '100%' }}>
                    <Card className="p-0 ml-0 mr-2 my-1" style={{ borderRadius: '14px' }}>
                        <CardBody>
                            <Switch>
                                <Route path='/omni/settings/shipping/:shippingMethod'
                                    component={(props) => (
                                        <ErrorBoundary
                                            FallbackComponent={ErrorFallbackModal}
                                        >
                                            <ShippingSettings {...props} reloadShippingMethods={reloadShippingMethods} toggleShipping={toggleShipping} />
                                        </ErrorBoundary>
                                    )}>
                                </Route>
                            </Switch>
                        </CardBody>
                    </Card>
                </SimpleBar>
            </Col>
        </div>
    )
}

const ShippingSettings = ({ match, location, reloadShippingMethods, toggleShipping }) => {

    const [shippingMethodInfo, setShippingMethodInfo] = useState('')

    useEffect(() => {

        toggleShipping(match.params.shippingMethod)

        OmniApi.get('/shippingmethods/' + match.params.shippingMethod)
            .then(res => {
                // create array of zoneStates 
                let zoneStates = []
                for (let state in res.data.zoneStates) {
                    zoneStates.push(state)
                }
                res.data.zoneStatesArray = zoneStates;
                setShippingMethodInfo(res.data)
            })

    }, [match.params.shippingMethod, toggleShipping])

    const markUpTypes = [
        { value: '0', label: 'None' },
        { value: 'fixed', label: 'Fixed' },
        { value: 'percent', label: 'Percent' }
    ]

    const shippingMethods = [
        { methodName: 'UPS', methodId: 'ups' },
        { methodName: 'Fedex', methodId: 'fedex' },
        { methodName: 'USPS', methodId: 'usps' },
        { methodName: 'Free Shipping', methodId: 'freeshipping' },
        { methodName: 'Store Pick Up', methodId: 'storepickup' },
        { methodName: 'Hand Delivered', methodId: 'handdelivered' }
    ]

    const onCountrySelectChange = (selection) => {

        // let zoneStates = {}
        selection.forEach(s => shippingMethodInfo.zoneStates[s.code] = shippingMethodInfo.zoneStates[s.code] ? [...shippingMethodInfo.zoneStates[s.code]] : [])
        selection.forEach(s => console.log(shippingMethodInfo.zoneStates[s.code]))

        setShippingMethodInfo({
            ...shippingMethodInfo,
            // zoneStates: zoneStates,
            zoneStatesArray: selection.map(s => s.code)
        })
    }

    const onStateSelectChange = (selection) => {
        setShippingMethodInfo({
            ...shippingMethodInfo,
            zoneStates: {
                ...shippingMethodInfo.zoneStates,
                US: selection.map(s => s.value)
            }
        })
    }

    const onZipcodeSelectChange = (selection) => {
        setShippingMethodInfo({
            ...shippingMethodInfo,
            zoneZipCodes: selection.map(s => {
                return { display: s.display, value: s.display }
            })
        })
        console.log(selection)
    }

    console.log('shippingMethodInfo', shippingMethodInfo)

    console.log('zonestates', shippingMethodInfo.zoneStates)

    const saveShippingSettings = () => {
        let newSettings = [{
            ...shippingMethodInfo
        }]

        delete newSettings[0].activeInAdmin
        delete newSettings[0].zoneStatesArray
        delete newSettings[0].companyToken
        delete newSettings[0].credentials
        delete newSettings[0].fetchUrl
        delete newSettings[0].maxOrder
        delete newSettings[0].methodName
        delete newSettings[0].methodSortOrder
        delete newSettings[0].minOrder
        delete newSettings[0].requiresDim
        delete newSettings[0].stopIfAvail
        delete newSettings[0].zoneCountries
        delete newSettings[0].zoneRadius

        const postShippingMethod = OmniApi.post('/shippingmethods', newSettings)
            .then(res => reloadShippingMethods())

        toast.promise(
            postShippingMethod,
            {
                pending: {
                    render() {
                        return "Saving settings..."
                    },
                    icon: true,
                },
                success: {
                    render(res) {
                        return `Settings Saved`
                    },
                    // other options
                    icon: true,
                },
                error: {
                    render(err) {
                        // When the promise reject, data will contains the error
                        return `err`
                    },
                    icon: true,
                }
            }
        )
    }

    if (shippingMethodInfo) {

        console.log("shippingMethod", shippingMethods, shippingMethodInfo);
        return (
            <>
                <Row>
                    <Col xs="auto">
                        <h3>{shippingMethods?.find(method => method.methodId === shippingMethodInfo?.methodId)?.methodName}</h3>
                    </Col>
                    <Col className="pl-0 d-flex align-items-center">
                        <CustomInput
                            type="switch"
                            id="exampleCustomSwitch"
                            name="customSwitch"
                            checked={shippingMethodInfo.active === 1 ? true : false}
                            onChange={(e) => {
                                // OmniApi.post('/shippingMethods', [{
                                //     active: e.target.checked ? 1 : 0,
                                //     methodId: shippingMethodInfo.methodId
                                // }])
                                setShippingMethodInfo({
                                    ...shippingMethodInfo,
                                    active: e.target.checked ? 1 : 0
                                })
                            }}
                        />
                    </Col>
                </Row>
                <hr className="mr-1 mt-0" />
                <Row>
                    <Col md={'auto'}>
                        <FormGroup>
                            <Label>Markup</Label>
                            <div className="mr-0" style={{ width: '220px' }}>
                                <Select
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    value={markUpTypes.find(option => option.value === shippingMethodInfo.markupType)
                                        ||
                                        { id: '0', label: 'None' }
                                    }
                                    onChange={(s) => {
                                        setShippingMethodInfo({
                                            ...shippingMethodInfo,
                                            markupType: s.value
                                        })
                                    }}
                                    isSearchable={false}
                                    options={markUpTypes}>
                                </Select>
                            </div>
                        </FormGroup>
                    </Col>
                    <Col md={'auto'}>
                        <FormGroup>
                            <Label for="discountValue">Markup Value</Label>
                            <InputGroup style={{ width: '150px' }}>
                                {(shippingMethodInfo.markupType === 'fixed') &&
                                    <InputGroupAddon addonType="prepend">$</InputGroupAddon>}
                                <Input type="number" placeholder="Value..." id="markup" name="markup"
                                    value={shippingMethodInfo.markup}
                                    onChange={(e) => setShippingMethodInfo({
                                        ...shippingMethodInfo,
                                        markup: e.target.value
                                    })}
                                    disabled={shippingMethodInfo.markupType === '0'}
                                />
                                {(shippingMethodInfo.markupType === 'percent') &&
                                    <InputGroupAddon addonType="append">%</InputGroupAddon>}
                            </InputGroup>
                        </FormGroup>
                    </Col>
                </Row>
                <hr className="w-75" />
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>Add Countries:</Label>
                            <Typeahead
                                // inputProps={{
                                //     className: `${!validState.selectedLocations ? 'invalid' : ''}`
                                // }}
                                paginate
                                maxResults={15}
                                id="select2"
                                labelKey="name"
                                multiple
                                options={CountryList.map(country => country)}
                                // emptyLabel={<div onClick={() => alert('clicked')}>hello</div>}
                                onChange={onCountrySelectChange}
                                selected={(shippingMethodInfo.zoneStatesArray.length) ?
                                    shippingMethodInfo.zoneStatesArray.map(locCode => CountryList.find(cntry => cntry.code === locCode))
                                        .filter(loc => loc !== undefined)
                                    :
                                    []
                                }
                                placeholder="Select country..."
                            // disabled={coupon.locationsEligibleType === 'all'}
                            />
                            {/* {!validState.selectedLocations ? <div className="invalid-text">No locations selected</div> : ''}
                {(coupon.locationsEligibleType === 'all') && <div className="overlay rounded"></div>} */}
                        </FormGroup>
                    </Col>
                </Row>
                {(shippingMethodInfo.zoneStatesArray.indexOf('US') >= 0) &&
                    <>
                        <hr className="w-75" />
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>Add US States and Territories: (<strong>{shippingMethodInfo.zoneStates['US'].length}</strong> of <strong>{StateList.length}</strong>)</Label>
                                    <Typeahead
                                        // inputProps={{
                                        //     className: `${!validState.selectedLocations ? 'invalid' : ''}`
                                        // }}
                                        paginate
                                        maxResults={15}
                                        id="select2"
                                        labelKey="label"
                                        multiple
                                        options={StateList.map(state => state)}
                                        // emptyLabel={<div onClick={() => alert('clicked')}>hello</div>}
                                        onChange={onStateSelectChange}
                                        selected={(shippingMethodInfo.zoneStates.hasOwnProperty('US')) ?
                                            shippingMethodInfo.zoneStates['US'].map(locCode => StateList.find(cntry => cntry.value === locCode))
                                                .filter(loc => loc !== undefined)
                                            :
                                            []
                                        }
                                        placeholder="Select country..."
                                    // disabled={coupon.locationsEligibleType === 'all'}
                                    />
                                    {/* {!validState.selectedLocations ? <div className="invalid-text">No locations selected</div> : ''}
                {(coupon.locationsEligibleType === 'all') && <div className="overlay rounded"></div>} */}
                                </FormGroup>
                            </Col>
                        </Row>
                    </>
                }
                <hr className="w-75" />
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>Add Zipcodes: </Label>
                            <Typeahead
                                // inputProps={{
                                //     className: `${!validState.selectedLocations ? 'invalid' : ''}`
                                // }}
                                defaultOpen={false}
                                allowNew
                                paginate
                                labelKey="display"
                                newSelectionPrefix="Add: "
                                maxResults={15}
                                id="select2"
                                multiple
                                options={[{ name: 'null' }]}
                                minLength={3}
                                // emptyLabel={<div onClick={() => alert('clicked')}>hello</div>}
                                onChange={onZipcodeSelectChange}
                                selected={shippingMethodInfo.zoneZipCodes}
                                placeholder="Add zipcodes..."
                            // disabled={coupon.locationsEligibleType === 'all'}
                            />
                            {/* <Select
                    isMulti={true}
                    className="react-select"
                    classNamePrefix="react-select"
                    noOptionsMessage={() => null}
                    placeholder="Add zipcode"
                    >
                    </Select>     */}
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col className="d-flex justify-content-end">
                        <Button color="primary" size="lg" style={{ borderRadius: '3px' }}
                            onClick={saveShippingSettings}
                        >Save</Button>
                    </Col>
                </Row>
            </>
        )
    }
    else
        return (
            <>
                <Row className="mb-2">
                    <Col>
                        <Skeleton height={30} width={350} />
                    </Col>
                </Row>
                <Row className="mb-1">
                    <Col>
                        <Skeleton style={{ width: '70%' }} />
                    </Col>
                </Row>
                <Row className="mb-1">
                    <Col>
                        <Skeleton style={{ width: '50%' }} />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Skeleton style={{ width: '30%' }} />
                    </Col>
                </Row>
                <Row className="mb-1">
                    <Col>
                        <Skeleton style={{ width: '60%' }} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Skeleton style={{ width: '60%' }} />
                    </Col>
                </Row>
            </>
        )
}

const Files = props => {

    const { toggle } = props;

    useEffect(() => {
        toggle('files')
    }, [toggle])

    return (
        <div className='h-100 w-100 d-flex justify-content-center align-items-center'>
            <Button size="lg" color="info" className='mb-4'
                onClick={() => window.open("https://fs1.cloudsnob.com/login")}>
                Navigate to FS1
                <Icon icon={arrowTopRightBoldBoxOutline} className='ml-1 mb-2' />
            </Button>
        </div>
    )
}

const Webhooks = props => {

    const { toggle } = props;

    const [webhooks, setWebhooks] = useState([]);

    const [webhookModal, setWebhookModal] = useState(false);

    const toggleWebhookModal = () => setWebhookModal(!webhookModal);

    const saveWebhook = (webhook) => {
        let postWebhook = OmniApi.post('/webhooks', webhook)
            .then(({ data }) => setWebhooks([
                ...webhooks,
                data
            ]))
            .catch(err => console.error(err))

        toast.promise(
            postWebhook,
            {
                pending: {
                    render() {
                        return "Saving Webhook..."
                    },
                    icon: true,
                },
                success: {
                    render(res) {
                        return `Webhook Saved`
                    },
                    // other options
                    icon: true,
                },
                error: {
                    render(err) {
                        // When the promise reject, data will contains the error
                        return `err`
                    },
                    icon: true,
                }
            }
        )
    }

    useEffect(() => {
        OmniApi.get('/webhooks')
            .then(({ data }) => setWebhooks(data))
            .catch(err => console.error(err))
    }, [])

    useEffect(() => {
        toggle('webhooks')
    }, [toggle])

    const deleteWebhook = async (token) => {
        if (await confirm(`Deleted webhooks cannot be recovered. Do you still want to proceed? `, "Deleting Webhook...", "Delete Webhook") === false) {
            return
        }

        const deleteWebhook = OmniApi.delete('/webhooks/' + token)
            .then(res => setWebhooks(webhooks.filter(webhook => webhook.webhookToken !== token)))
            .catch(err => console.error(err))

        toast.promise(
            deleteWebhook,
            {
                pending: {
                    render() {
                        return "Deleting Webhook..."
                    },
                    icon: true,
                },
                success: {
                    render(res) {
                        return `Webhook Deleted`
                    },
                    // other options
                    icon: true,
                },
                error: {
                    render(err) {
                        // When the promise reject, data will contains the error
                        return `err`
                    },
                    icon: true,
                }
            }
        )
    }

    const webhookTriggerOptions = [
        {
            value: 'createProduct',
            label: 'Product Created',
        },
        {
            value: 'editProduct',
            label: 'Product Updated',
        },
        {
            value: 'createOrder',
            label: 'New Order',
        },
        {
            value: 'editOrder',
            label: 'Order Updated',
        },
        {
            value: 'newCustomer',
            label: 'New Customer',
        },
        // {
        // 	value : 'editCustomer',
        // 	label : 'Customer Update',
        // }
    ]

    return (
        <>
            <Table className="mb-0">
                <thead className='bg-light'>
                    <tr>
                        <th></th>
                        <th style={{ verticalAlign: 'middle' }}>Trigger</th>
                        <th style={{ verticalAlign: 'middle' }}>Webhook Url</th>
                        <th style={{ verticalAlign: 'middle', textAlign: 'end' }}><Button color='primary' onClick={toggleWebhookModal}>
                            + Add Webhook
                        </Button></th>
                    </tr>
                </thead>
                <tbody>
                    {webhooks.map(webhook => {
                        return (
                            <tr key={webhook.webhookToken}>
                                <td></td>
                                <td style={{ verticalAlign: 'middle' }}>
                                    <strong>{webhookTriggerOptions.find(webhookTrigger => webhookTrigger.value === webhook.webhookTrigger).label}</strong>
                                </td>
                                <td style={{ verticalAlign: 'middle' }}>{webhook.webhook}</td>
                                <td className='d-flex justify-content-end align-items-center'>
                                    <Button size='sm' color='danger' className='btn-icon btn-circle btn-circle-md d-flex align-items-center justify-content-center mr-2'
                                        onClick={() => deleteWebhook(webhook.webhookToken)}
                                    >
                                        <Icon icon={deleteIcon} width="21" height="21" />
                                    </Button>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            <AddWebhookModal
                toggleWebhookModal={toggleWebhookModal}
                webhookModal={webhookModal}
                saveWebhook={saveWebhook}
            />
        </>
    )
}

const AddWebhookModal = ({ toggleWebhookModal, webhookModal, saveWebhook }) => {
    const [webhook, setWebhook] = useState({
        webhookTrigger: '',
        webhook: ''
    });

    const webhookTriggerOptions = [
        {
            value: 'createProduct',
            label: 'Product Created',
        },
        {
            value: 'editProduct',
            label: 'Product Updated',
        },
        {
            value: 'createOrder',
            label: 'New Order',
        },
        {
            value: 'editOrder',
            label: 'Order Updated',
        },
        {
            value: 'newCustomer',
            label: 'New Customer',
        },
        // {
        // 	value : 'editCustomer',
        // 	label : 'Customer Update',
        // }
    ]

    const [webhookError, setWebhookError] = useState(false);
    const [webhookTriggerError, setWebhookTriggerError] = useState(false);

    const onSelectChange = (selection) => {
        setWebhookTriggerError(false);
        setWebhook({
            ...webhook,
            webhookTrigger: selection.value
        })
    }

    const onInputChange = (e) => {
        setWebhookError(false);
        setWebhook({
            ...webhook,
            webhook: e.target.value,
        })
    }

    const toggle = () => {
        setWebhook({
            webhookTrigger: '',
            webhook: ''
        })
        toggleWebhookModal()
    }

    const onSave = () => {
        let error = false;

        if (!webhook.webhook) {
            setWebhookError(true)
            error = true;
        }

        if (!webhook.webhookTrigger) {
            setWebhookTriggerError(true)
            error = true;
        }

        if (error) return

        saveWebhook(webhook);
        toggle()
    }

    return (
        <Modal isOpen={webhookModal} toggle={toggle}>
            <ModalHeader toggle={toggle} size="lg">
                Add Webhook
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for="webhookType">Trigger</Label>
                            <div style={{ width: '250px' }}>
                                <Select
                                    id="webhookType"
                                    className={`${webhookTriggerError ? 'invalid-select' : 'react-select'}`}
                                    classNamePrefix="react-select"
                                    onChange={onSelectChange}
                                    value={webhookTriggerOptions.find(option => option.value === webhook.webhookTrigger)}
                                    isSearchable={false}
                                    options={webhookTriggerOptions}>
                                </Select>
                            </div>
                            {(webhookTriggerError) && <div className="invalid-text">Webhook Trigger is required</div>}
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for="webhookLink">Webhook Url</Label>
                            <Input type="text" id="webhookLink"
                                className={`form-control ${webhookError ? 'invalid' : ''}`}
                                value={webhook.webhook}
                                onChange={onInputChange}
                            ></Input>
                            {(webhookError) && <div className="invalid-text">Webhook Url is required</div>}
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter className="d-flex justify-content-between">
                <Button color="light" onClick={toggle}>
                    Cancel
                </Button>{' '}
                <Button color="primary" onClick={onSave}>
                    Save
                </Button>{' '}
            </ModalFooter>
        </Modal>
    )
}

const Tax = props => {

    const { toggle } = props;

    useEffect(() => {
        toggle('tax')
    }, [toggle])

    return (
        <div className='h-100 w-100 d-flex justify-content-center align-items-center'>
            <strong>Tax settings coming soon...</strong>
        </div>
    )
}

const SiteSettings = ({ toggle }) => {

    const [unsavedChanges, setUnsavedChanges] = useState(false);

    const [showPassword, setShowPassword] = useState(false);

    const [loading, setLoading] = useState(true);

    const [siteSettings, setSiteSettings] = useState({
        baseUrl: '',
        googleSheetId: '',
        smtpEmail: '',
        smtpHost: '',
        smtpPassword: '',
        deleteSessionsRuleInDays: 0,
        cartQuantityCombine: false,
        sendOrderEmail: false,
        sendWelcomeEmail: false,
        quotesEnabled: false,
        signupStatus: null
    })

    const [customerStatuses, setCustomerStatuses] = useState([])

    useEffect(() => {
        toggle('site')
    }, [toggle])

    useEffect(() => {
        OmniApi.get('/settingssite')
            .then(res => {
                setSiteSettings(res.data)
            })
            .catch(err => {
                toast.error('Error getting site settings')
            })
            .finally(() => setLoading(false))
    }, [])

    useEffect(() => {
        OmniApi.get(`/lists/customerstatuses`)
            .then(res => setCustomerStatuses(res.data.map(status => ({ value: status.id, label: status.name }))))
            .catch(err => alert('error', err))
    }, [])

    function handleValidSubmit() {
        OmniApi.post('/settingssite', siteSettings)
            .then(res => {
                setUnsavedChanges(false)
                toast.success('Site settings saved')
            })
            .catch(err => {
                toast.error('Error saving site settings')
            })
    }

    const handleInputChange = (e) => {
        const target = e.target;
        const name = target.name;
        const type = target.type;

        setUnsavedChanges(true)

        if (type === 'checkbox') {
            setSiteSettings({
                ...siteSettings,
                [name]: e.target.checked ? 1 : 0
            })
        } else {
            setSiteSettings({
                ...siteSettings,
                [name]: e.target.value
            })
        }
    }

    console.log({ siteSettings })

    return (
        <>
            <PageLock unsavedChanges={unsavedChanges} setUnsavedChanges={setUnsavedChanges} />
            {loading ?
                <div className='page-loading-loader-div w-100 d-flex justify-content-center align-items-center'>
                    <div>
                        <h4>Loading site settings...</h4>
                        <div className="bouncing-loader">
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </div>
                :
                <Container style={{ width: '80%', overflow: 'auto' }} className="mt-2 mb-2">
                    <AvForm
                        // beforeSubmitValidation={customValidateForm}
                        onValidSubmit={handleValidSubmit}>
                        <h4>Site Settings</h4>
                        <Row>
                            <Col>
                                <div className='border-top mb-2' style={{ height: '1px', width: '350px' }}>

                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <div className='d-flex'>
                                        <Label>Default Customer Status</Label>
                                        <Icon icon={informationOutline} style={{ marginLeft: '2px' }} color="#39afd1" id="signup-status" />
                                        <UncontrolledTooltip placement="top" target="signup-status">
                                            Status assigned to customer on sign-up. If status is "Waiting Approval", Admin will need to approve the customer before the customer can access the site.
                                        </UncontrolledTooltip>
                                    </div>
                                    <Select
                                        isMulti={false}
                                        isSearchable={false}
                                        name="signupStatus"
                                        options={
                                            customerStatuses
                                        }
                                        onChange={(selection) => {
                                            console.log('check', customerStatuses.find(status => status.value === siteSettings.signupStatus))
                                            console.log(selection)
                                            setSiteSettings(
                                                {
                                                    ...siteSettings,
                                                    signupStatus: selection.value
                                                }
                                            )
                                        }}
                                        value={customerStatuses.find(status => status.value === siteSettings.signupStatus) || ''}
                                        className="react-select"
                                        classNamePrefix="react-select"></Select>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup className='d-flex'>
                                    <CustomInput
                                        type="checkbox"
                                        name="cartQuantityCombine"
                                        id="cartQuantityCombine"
                                        label="Cart Quantity Combine"
                                        checked={!!siteSettings.cartQuantityCombine}
                                        onChange={handleInputChange}
                                    />
                                    <Icon icon={informationOutline} style={{ marginLeft: '2px' }} color="#39afd1" id="cart-quantity-combine" />
                                    <UncontrolledTooltip placement="top" target="cart-quantity-combine">
                                        When a user signs-in, will their cart from before sign-in be combined with their existing cart
                                    </UncontrolledTooltip>
                                </FormGroup>
                                <FormGroup className='d-flex'>
                                    <CustomInput
                                        type="checkbox"
                                        name="quotesEnabled"
                                        id="quotesEnabled"
                                        label="Enable Quotes"
                                        checked={!!siteSettings.quotesEnabled}
                                        onChange={handleInputChange}
                                    />
                                    <Icon icon={informationOutline} style={{ marginLeft: '2px' }} color="#39afd1" id="quotes-enabled" />
                                    <UncontrolledTooltip placement="top" target="quotes-enabled">
                                        Enable quotes feature on the site
                                    </UncontrolledTooltip>
                                </FormGroup>
                            </Col>
                        </Row>
                        <h4>URL</h4>
                        <Row>
                            <Col>
                                <div className='border-top mb-2' style={{ height: '1px', width: '350px' }}>

                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <AvField
                                    type="text"
                                    name="baseUrl"
                                    label="Base Url"
                                    placeholder="https://..."
                                    value={siteSettings.baseUrl}
                                    onChange={handleInputChange}
                                // validate={{
                                //     required: {value: true, errorMessage: 'Please enter a first name'},
                                // }}
                                />
                            </Col>
                        </Row>
                        <h4>Google Settings</h4>
                        <Row>
                            <Col>
                                <div className='border-top mb-2' style={{ height: '1px', width: '350px' }}>

                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <AvField
                                        type="text"
                                        name="googleSheetId"
                                        label="Google Sheet ID"
                                        placeholder=""
                                        value={siteSettings.googleSheetId}
                                        onChange={handleInputChange}
                                    // validate={{
                                    //     required: {value: true, errorMessage: 'Please enter a first name'},
                                    // }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <h4>Email Settings</h4>
                        <Row>
                            <Col>
                                <div className='border-top mb-2' style={{ height: '1px', width: '350px' }}>

                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup className='d-flex'>
                                    <CustomInput
                                        type="checkbox"
                                        name="sendOrderEmail"
                                        id="sendOrderEmail"
                                        label="Send Order Email"
                                        checked={!!siteSettings.sendOrderEmail}
                                        onChange={handleInputChange}
                                    />
                                    <Icon icon={informationOutline} style={{ marginLeft: '2px' }} color="#39afd1" id="send-order-email" />
                                    <UncontrolledTooltip placement="top" target="send-order-email">
                                        Send email when a customer places an online order
                                    </UncontrolledTooltip>
                                </FormGroup>
                                <FormGroup className='d-flex'>
                                    <CustomInput
                                        type="checkbox"
                                        name="sendWelcomeEmail"
                                        id="sendWelcomeEmail"
                                        label="Send Welcome Email"
                                        checked={!!siteSettings.sendWelcomeEmail}
                                        onChange={handleInputChange}
                                    />
                                    <Icon icon={informationOutline} style={{ marginLeft: '2px' }} color="#39afd1" id="send-welcome-email" />
                                    <UncontrolledTooltip placement="top" target="send-welcome-email">
                                        Send email when a customer signs-up
                                    </UncontrolledTooltip>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <AvField
                                        type="text"
                                        name="smtpHost"
                                        label="SMTP Host"
                                        placeholder=""
                                        value={siteSettings.smtpHost}
                                        onChange={handleInputChange}
                                    // validate={{
                                    //     required: {value: true, errorMessage: 'Please enter a first name'},
                                    // }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <AvField
                                        type="text"
                                        name="smtpEmail"
                                        label="SMTP Email"
                                        placeholder=""
                                        autoComplete="new-password"
                                        value={siteSettings.smtpEmail}
                                        onChange={handleInputChange}
                                    // validate={{
                                    //     required: {value: true, errorMessage: 'Please enter a first name'},
                                    // }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <AvGroup className="position-relative">
                                        <Label for="smtpPassword">SMTP Password</Label>
                                        <div className="input-group">
                                            <AvInput type={!showPassword ? "password" : "text"} placeholder="" name="smtpPassword"
                                                value={siteSettings.smtpPassword}
                                                autoComplete="new-password"
                                                onChange={handleInputChange}
                                            />
                                            <InputGroupAddon addonType="append">
                                                <Button color="secondary" className='d-flex justify-content-center align-items-center' title="Show Password"
                                                    onClick={() => setShowPassword(!showPassword)}
                                                >
                                                    {showPassword ? 'Hide' : 'Show'}
                                                </Button>
                                            </InputGroupAddon>
                                        </div>
                                    </AvGroup>
                                </FormGroup>
                            </Col>
                        </Row>
                        <hr className='mb-3' />
                        <Row>
                            <Col>
                                <Button size="lg" className='float-right'
                                    color="primary"
                                    disabled={!unsavedChanges}
                                >
                                    Save
                                </Button>
                            </Col>
                        </Row>
                    </AvForm>
                </Container>}
        </>
    )
}

export default Settings;