import React, {useState, useEffect, useContext } from 'react';
import { OmniApi } from '../customAPI/OmniApi';
import { Row, Col, Card, CardBody, FormGroup, Label, Input, InputGroup, InputGroupAddon, UncontrolledTooltip, ModalBody, ModalFooter, Modal, ModalHeader, Button } from 'reactstrap';
import { Icon } from '@iconify/react';
import pencilIcon from '@iconify/icons-mdi/pencil';
import contentSave from '@iconify/icons-mdi/content-save';
import contentCopy from '@iconify/icons-mdi/content-copy';
import closeThick from '@iconify/icons-mdi/close-thick';
import plusCircle from '@iconify/icons-mdi/plus-circle';
import PageLock from '../utility-components/PageLock';
import { Helmet } from 'react-helmet';
import ImageUpload from './ImageUpload';
import { UserContext } from '../custom-contexts/UserContext';
import axios from 'axios';
import { confirm } from './Confirm';
import shoppingIcon from '@iconify/icons-mdi/shopping';
import shapeIcon from '@iconify/icons-mdi/shape';
import linkVariant from '@iconify/icons-mdi/link-variant';
import Select from 'react-select';
import { Typeahead } from 'react-bootstrap-typeahead';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { toast } from 'react-toastify';
import navigationVariant from '@iconify/icons-mdi/navigation-variant';

const NavigationDetail = props => {

    const [navigation, setNavigation] = useState('');

    const [unsavedChanges, setUnsavedChanges] = useState(false);

    const { user } = useContext(UserContext);

    function isEmpty(obj) {
        return Object.keys(obj).length === 0;
    }

    useEffect(() => {
        let navigationToken = props.match.params.navigationToken;
        if (navigationToken){
            OmniApi.get(`/navigations/${navigationToken}`)
            .then(res => {
                if (isEmpty(res.data))
                    throw new Error('Invalid Navigation Token')

                setNavigation(res.data)
            })
            .catch(err => {
                props.history.replace('/omni/navigations')
                toast.error(err.message)
            })
        } else {
            setNavigation({
                name: '',
                slug: '',
                navigationImage: '',
                navigationToken: '',
                items:[]
            })
        }
    }, [props.location])

    const [productList, setProductList] = useState([])

    useEffect(() => {
        OmniApi.get('/products')
        .then(res => setProductList(res.data))
        .catch(err => alert(err))
    }, [])

    const [collectionList, setCollectionList] = useState([]);

    useEffect(() => {
        OmniApi.get(`/collections`)
        .then(res => setCollectionList(res.data));
      }, []);

    const [navigationList, setNavigationList] = useState([]);

    useEffect(() => {
        OmniApi.get(`/navigations`)
        .then(res => setNavigationList(res.data));
      }, []);

    const [slugDisabled, setSlugDisabled] = useState(true);

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
    }

    const generateSlug = (string) => string?.toLowerCase().replace(/[^a-zA-Z0-9]+/g, "-");

    const [nameRequired, setNameRequired] = useState(false);

    const handleInputChange = (e) => {
        const target = e.target;
        const name = target.name;

        setUnsavedChanges(true)

        if (name == 'name'){
            setNameRequired(false);
        }

        if (name === 'slug'){
            return setNavigation({
                ...navigation,
                [name]: generateSlug(e.target.value)
            })
        }

        setNavigation({
            ...navigation,
            [name]: e.target.value
        })
    }

    const uploadImage = (img) => {
        let payload = new FormData();

        payload.append('ev_file', img);

        axios.post('https://fs1.cloudsnob.com/upload', payload, {
            headers: {
                'upload_token': user.company.fs1Token
              }
        })
        .then(res => setNavigation({...navigation, navigationImage: res.data.path}))
    }

    const handleImageUpload = (e) => {
        setUnsavedChanges(true)
        const file = e.target.files[0];
        if(file){
            uploadImage(file);
        }
        else return
    }

    const deleteImage = async () => {
        if (await confirm("Delete navigation image?", "Deleting Image", "Delete")){
            setUnsavedChanges(true)
            setNavigation({
                ...navigation,
                navigationImage: ''
            });
        }
        else return
    }

    const [addItemModal, setAddItemModal] = useState(false);

    const toggleAddItemModal = () => {
        setAddItemModal(!addItemModal);
    }
    
    const addItemToNavigation = (item) => {
        setNavigation({
            ...navigation,
            items:[
                ...navigation.items,
                item
            ]
        })
        toggleAddItemModal()
    }

    console.log('NAVIGATION ITEMS', navigation.items)

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

      const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const handleOnDragEnd = result => {
        const { source, destination } = result;
        // dropped outside the list
        if (!destination) {
            return;
        }

        if (source.droppableId === destination.droppableId) {
            const items = reorder(navigation[destination.droppableId], source.index, destination.index);

            setNavigation({
                ...navigation,
                [destination.droppableId]: items
            })

        } else {
            return
        }
    }

    function getItemName(type, token) {
        if (type === 'collection') {
            return collectionList.find(c => c.collectionToken === token)?.collectionName;
        }
        if (type === 'product') {
            return productList.find(p => p.prodToken === token)?.prodName;
        }
        if (type === 'navigation') {
            return navigationList.find(p => p.navigationToken === token)?.name;
        }
    }

    const getIcon = (type) => {
        if(type === 'product')
            return shoppingIcon
        if(type === 'collection')
            return shapeIcon
        if(type === 'link')
            return linkVariant
        if(type === 'navigation')
            return navigationVariant
    }

    const saveNavigation = () => {

        let newNavigation = { ...navigation }
        if (!newNavigation.name)
            return setNameRequired(true)

        if (!newNavigation.slug)
            newNavigation.slug = generateSlug(newNavigation.name)

            newNavigation.items.forEach((item, i) => item.sort = i);

        if(!newNavigation.navigationToken)
            delete newNavigation.navigationToken;

        let postNavigation = OmniApi.post(`/navigations${newNavigation.navigationToken ? '/' + navigation.navigationToken : ''}`, newNavigation)
        .then(res => {
            setUnsavedChanges(false)
            props.history.replace(`${props.location.pathname}${navigation.navigationToken ? '' : '/' + res.data.navigationToken}`)})

        toast.promise(
            postNavigation,
            {
                pending: {
                    render() {
                        return "Saving navigation..."
                    },
                    icon: true,
                },
                success: {
                    render(res) {
                        return `Navigation saved`
                    },
                    // other options
                    icon: true,
                },
                error: {
                    render(err) {
                        // When the promise reject, data will contains the error
                        return `${err.data}`
                    },
                    icon: true,
                }
            }
        )

    }

    const deleteNavigation = async () => {

        if (await confirm(`Delete '${navigation.name}' from navigations?`, "Deleting Navigation...", "Delete") === false) {
            return
        }

        let deleteNav = OmniApi.delete(`/navigations/${navigation.navigationToken}`)
        .then(res => props.history.replace('/omni/navigations'))
        .catch(err => console.error(err))

        toast.promise(
            deleteNav,
            {
                pending: {
                    render() {
                        return "Deleting collection..."
                    },
                    icon: true,
                },
                success: {
                    render(res) {
                        return `'${navigation.name}' deleted from navigations`
                    },
                    // other options
                    icon: true,
                },
                error: {
                    render(err) {
                        // When the promise reject, data will contains the error
                        return `err`
                    },
                    icon: true,
                }
            }
        )

    }

    const removeNavigationItem = async (navItem) => {

        if (navItem.hasOwnProperty('navigationItemToken')){
            if (await confirm('Are you sure you want to delete "' + getItemName(navItem.itemType, navItem.itemToken) + '" from navigations?', "Deleting Navigatiion Item", "Delete") === false)
                return
            OmniApi.delete('/navigations/' + navItem.navigationItemToken)
            .then(res => {
                if(res.data.hasOwnProperty('deleted') && res.data.deleted){
                    setNavigation({
                        ...navigation,
                        items: navigation.items.filter(item => item.id !== navItem.id)
                    })
                    toast.success('Navigation item deleted')
                } else {
                    throw new Error('Error deleting navigation item')
                }
            })
            .catch(err => toast.error(err.message))
        } else {
            setNavigation({
                ...navigation,
                items: navigation.items.filter(item => item.id !== navItem.id)
            })
        }
        
    }

    const AddItemModal = () => {

        const [item, setItem] = useState({
            itemType: '',
            itemToken:'',
            link: ''
        })

        const onSelectChange = (selection) => {
            setItem({
                itemType: selection.value,
                itemToken: '',
                link: ''
            })
        }

        const onItemSelectChange = (select) => {

            if (item.itemType === 'product')
                setItem({
                    ...item,
                    itemToken: select.length ? select[0].prodToken : '',
                    link: ''
                })
            
            if (item.itemType === 'collection')
                setItem({
                    ...item,
                    itemToken: select.length ? select[0].collectionToken : '',
                    link: ''
                })

            if (item.itemType === 'navigation')
                setItem({
                    ...item,
                    itemToken: select.length ? select[0].navigationToken : '',
                    link: ''
                })
        }

        function onLinkChange(e){
            setItem({
                ...item,
                itemToken: '',
                link: e.target.value
            })
        }

        console.log('item', item)

        const submitItem = () => {
            if(!item.itemToken && !item.link)
                return
            
            function hasWhiteSpace(s) {
            return /\s/g.test(s);
            }

            if (item.link && hasWhiteSpace(item.link))
                return toast.error('Link must not contain white spaces')
            
            let newItem = { ...item }

            newItem.id = Math.random().toString(36).slice(-8);

            addItemToNavigation(newItem)
        }

        return(
            <Modal isOpen={addItemModal} toggle={toggleAddItemModal}>
                <ModalHeader toggle={toggleAddItemModal}>
                    Add Item to Navigation
                </ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label for="itemType">Select Item Type</Label>
                        <Select
                        id="itemType"
                        className="react-select"
                        classNamePrefix="react-select"
                        onChange={onSelectChange}
                        isSearchable={false}
                        options={[
                            { value: 'product', label: 'Product' },
                            { value: 'collection', label: 'Collection' },
                            { value: 'link', label: 'Link' },
                            { value: 'navigation', label: 'Navigation' }
                        ]}></Select>
                    </FormGroup>
                    {(item.itemType === 'product') &&
                    <FormGroup>
                        <Label for="itemType">Enter Product</Label>
                        <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <span className="input-group-text py-0 px-1">
                                <Icon icon={shoppingIcon} width="25" height="25" />
                            </span>
                        </InputGroupAddon>
                        <Typeahead
                        paginate
                        maxResults={15}
                        id="select2"
                        labelKey="prodName"
                        multiple={false}
                        options={productList.map(product => product)}
                        // emptyLabel={<div onClick={() => alert('clicked')}>hello</div>}
                        onChange={onItemSelectChange}
                        placeholder="Select product..."
                        />
                        </InputGroup>
                    </FormGroup>
                    }
                    {(item.itemType === 'collection') &&
                    <FormGroup>
                        <Label for="itemType">Enter Collection</Label>
                        <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <span className="input-group-text py-0 px-1">
                                <Icon icon={shapeIcon} width="25" height="25" />
                            </span>
                        </InputGroupAddon>
                        <Typeahead
                        paginate
                        maxResults={15}
                        id="select2"
                        labelKey="collectionName"
                        multiple={false}
                        options={collectionList.map(collection => collection)}
                        // emptyLabel={<div onClick={() => alert('clicked')}>hello</div>}
                        onChange={onItemSelectChange}
                        placeholder="Select collection..."
                        />
                        </InputGroup>
                    </FormGroup>
                    }
                    {(item.itemType === 'link') &&
                    <FormGroup>
                        <Label for="link">Enter Link</Label>
                        <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <span className="input-group-text py-0 px-1">
                                <Icon icon={linkVariant} width="25" height="25" />
                            </span>
                        </InputGroupAddon>
                        <Input name="link" id="link" onChange={onLinkChange} placeholder="Enter link...">
                        </Input>
                        </InputGroup>
                    </FormGroup>
                    }
                    {(item.itemType === 'navigation') &&
                    <FormGroup>
                        <Label for="itemType">Enter Navigation</Label>
                        <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <span className="input-group-text py-0 px-1">
                                <Icon icon={navigationVariant} width="25" height="25" />
                            </span>
                        </InputGroupAddon>
                        <Typeahead
                        paginate
                        maxResults={15}
                        id="select2"
                        labelKey="name"
                        multiple={false}
                        options={navigationList.filter(nav => nav.navigationToken !== navigation.navigationToken).map(nav => nav)}
                        // emptyLabel={<div onClick={() => alert('clicked')}>hello</div>}
                        onChange={onItemSelectChange}
                        placeholder="Select Navigation..."
                        />
                        </InputGroup>
                    </FormGroup>
                    }
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={submitItem} disabled={!item.itemToken && !item.link}>
                        Add Item
                    </Button>{' '}
                    <Button color="secondary" onClick={toggleAddItemModal}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }

    const truncateString = (string, length) => {
        if (!string)
            return
        let slicedString = string.slice(0, length)
        if (string.length > length)
        slicedString = slicedString.concat('...')
        return slicedString
    }

    if (navigation)
        return(
            <>
                <PageLock unsavedChanges={unsavedChanges} setUnsavedChanges={setUnsavedChanges} />
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Navigation Detail | {process.env.REACT_APP_HEADER_TITLE}</title>
                </Helmet>
                {navigation.navigationToken ? <h2>{navigation.name}</h2> 
                : 
                <h2>Create navigation</h2>}
                <Row>
                    <Col lg={7}>
                        <Card>
                            <CardBody>
                            <Row className="d-flex">
                                <Col sm={'auto'} className="ml-2">
                                    <div className="rounded" style={{ overflow: 'hidden' }}>
                                    <ImageUpload 
                                    handleImageUpload={handleImageUpload} 
                                    image={navigation.navigationImage} 
                                    deleteImage={deleteImage}
                                    allowDelete={true}
                                    imageSize={'300'}
                                    />
                                    </div>
                                </Col>
                                {/* <Col className="border-left px-0 ml-4" style={{ maxWidth: '10px', borderLeft: '3px solid #6c757d' }}>
                                </Col> */}
                                <Col sm={'auto'} className="ml-4">
                                    <FormGroup>
                                        <Label htmlFor="name">Navigation Name</Label>
                                        <Input type="text" name="name" id="name"
                                        value={navigation.name}
                                        onChange={handleInputChange}
                                        className={`${nameRequired ? 'invalid' : ''}`}
                                        placeholder="Navigation name..."
                                        />
                                        {nameRequired ? <div className="invalid-text">Navigation name is required</div> : ''}
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="slug">Navigation Handle</Label>
                                        <InputGroup>
                                            <Input type="text" name="slug" id="slug"
                                                value={navigation.slug}
                                                onChange={handleInputChange}
                                                placeholder="Navigation handle..."
                                                disabled={slugDisabled} />
                                            <InputGroupAddon addonType="append">
                                                <span
                                                    className="btn btn-secondary input-group-text py-0 px-1"
                                                    onClick={() => setSlugDisabled(!slugDisabled)}
                                                >
                                                    <Icon icon={slugDisabled ? pencilIcon : contentSave} width="25" height="25" />
                                                </span>
                                            </InputGroupAddon>
                                            <InputGroupAddon addonType="append">
                                                <span
                                                    className="btn btn-secondary input-group-text py-0 px-1"
                                                    onClick={
                                                        // this is necessary when there isn't a productSlug
                                                        () => copyToClipboard(navigation.slug
                                                            ||
                                                            generateSlug(navigation.name))}
                                                >
                                                    <Icon icon={contentCopy} width="25" height="25" id="copy-slug" />
                                                </span>
                                                <UncontrolledTooltip placement="top" target="copy-slug" trigger="legacy">
                                                    {navigation.slug} Copied to clipboard
                                                </UncontrolledTooltip>
                                            </InputGroupAddon>
                                        </InputGroup>
                                        <p className="mt-1 mb-0">
                                            <strong>Navigation Handle:</strong>{` "/omni/navigation/${navigation.slug || '{navigation_slug}'}"`}
                                        </p>
                                    </FormGroup>
                                </Col>
                            </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={5}>
                        <Card style={{ overflow: 'hidden' }}>
                            <div className="w-100 d-flex justify-content-center bg-secondary py-1 mb-2">
                                <h4 className="text-white">Navigation Items</h4>
                            </div>
                            <div className="w-100">
                                {/* <Card className="d-flex flex-row mx-3 mb-1" style={{ overflow: 'hidden' }}>
                                    <div className="bg-primary d-flex justify-content-center align-items-center p-1" style={{ width: '40px' }}>
                                        <Icon icon={shoppingIcon} height="30" width="30" color="white" />
                                    </div>
                                    <div className="ml-2 d-flex flex-row">
                                        <h5 className="d-flex align-items-center">Product:</h5>
                                        <div className="ml-1 d-flex align-items-center">Name of product</div>
                                    </div>
                                    <div className="ml-auto bg-danger d-flex justify-content-center align-items-center p-1">
                                        <Icon icon={closeThick} height="30" width="30" color="white" />
                                    </div>
                                </Card>
                                <Card className="d-flex flex-row mx-3 mb-1" style={{ overflow: 'hidden' }}>
                                    <div className="bg-primary d-flex justify-content-center align-items-center p-1" style={{ width: '40px' }}>
                                        <Icon icon={shapeIcon} height="30" width="30" color="white" />
                                    </div>
                                    <div className="ml-2 d-flex flex-row">
                                        <h5 className="d-flex align-items-center">Collection:</h5>
                                        <div className="ml-1 d-flex align-items-center">Name of collection</div>
                                    </div>
                                    <div className="ml-auto bg-danger d-flex justify-content-center align-items-center p-1">
                                        <Icon icon={closeThick} height="30" width="30" color="white" />
                                    </div>
                                </Card>
                                <Card className="d-flex flex-row mx-3 mb-1" style={{ overflow: 'hidden' }}>
                                    <div className="bg-primary d-flex justify-content-center align-items-center p-1" style={{ width: '40px' }}>
                                        <Icon icon={linkVariant} height="30" width="30" color="white" />
                                    </div>
                                    <div className="ml-2 d-flex flex-row">
                                        <h5 className="d-flex align-items-center">Link:</h5>
                                        <div className="ml-1 d-flex align-items-center">www.randomlink.com</div>
                                    </div>
                                    <div className="ml-auto bg-danger d-flex justify-content-center align-items-center p-1">
                                        <Icon icon={closeThick} height="30" width="30" color="white" />
                                    </div>
                                </Card>
                            </div> */}
                            <DragDropContext onDragEnd={handleOnDragEnd}>
                        <Droppable className="d-flex" droppableId='items'>
                            {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef} >
                                    {navigation.items.length ? navigation.items.map((item, i) => (
                                        <Draggable key={`${i}`} draggableId={`${i}`} index={i} className="d-flex">
                                            {(provided) => (<div ref={provided.innerRef} {...provided.draggableProps} 
                                            {...provided.dragHandleProps} className="mb-1">
                                            <Card className="d-flex flex-row mx-3 my-0" style={{ overflow: 'hidden' }}>
                                                <div className="bg-primary d-flex justify-content-center align-items-center p-1" style={{ width: '40px' }}>
                                                    <Icon icon={getIcon(item.itemType)} height="30" width="30" color="white" />
                                                </div>
                                                <div className="ml-2 d-flex flex-row" style={{ flex: 1, overflow: 'hidden'}}>
                                                    <h5 className="d-flex align-items-center">{capitalizeFirstLetter(item.itemType)}:</h5>
                                                    <div 
                                                    style={{ textOverflow: 'ellipsis', whiteSpace: 'normal', maxHeight: '44px', minWidth: 0 }}
                                                    className="ml-1 d-flex align-items-center">
                                                        <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                        {item.itemToken ? 
                                                        truncateString(getItemName(item.itemType, item.itemToken), 60) : item.link}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div 
                                                style={{ minWidth: '44px' }}
                                                className="btn btn-danger ml-auto d-flex justify-content-center align-items-center p-1"
                                                onClick={() => removeNavigationItem(item)}
                                                >
                                                    <Icon icon={closeThick} height="30" width="30" color="white" />
                                                </div>
                                            </Card>
                                            </div>)}</Draggable>
                                    ))
                                    :
                                    <div className="d-flex justify-content-center mb-1 mx-1 py-1"
                                    style={{ border: '2px dashed #E0E7ED', borderRadius: '0.25rem', color: '#E0E7ED' }}
                                    >
                                        <h5>No Items</h5>
                                    </div>
                                    }
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                    </div>
                            <div className="btn btn-light w-100 d-flex justify-content-center mt-1" onClick={toggleAddItemModal}>
                                <h5 className="py-0 my-0"><Icon icon={plusCircle} height="25" width="25" className="mr-1"/> Add Navigation Item</h5>
                            </div>
                        </Card>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <div className="button-list d-flex justify-content-end">
                            <Button color="danger" size="lg" disabled={!navigation.navigationToken}
                            onClick={deleteNavigation}
                            >Delete</Button>
                            <Button color="primary" size="lg" className="ml-3" 
                            onClick={saveNavigation}
                            >Save</Button>
                        </div>
                    </Col>
                </Row>
                <AddItemModal />
            </>
        )
    else
        return (
            <div className='page-loading-loader-div w-100 d-flex justify-content-center align-items-center'>
            <div>
            <h4>Loading navigation...</h4>
                <div className="bouncing-loader">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                </div>
            </div>
            )
}

export default NavigationDetail;