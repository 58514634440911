import React from 'react';
import { Prompt, withRouter } from 'react-router-dom';
import { confirm } from '../custom-components/Confirm';

class PageLock extends React.Component {

    constructor(props){
        super(props);

        this.handleUnlock = this.handleUnlock.bind(this);
        this.leavePageModal = this.leavePageModal.bind(this);
    }

    handleUnlock(location){
        this.leavePageModal(location)
        return false
    }

    async leavePageModal(location){
        if (await confirm(`You have unsaved changes. Leave page without saving changes?`, "Leave Page", "Leave")){
            this.props.setUnsavedChanges(false)
            this.props.history.push(location)
        }
    }

    render() {
        return(
            <Prompt 
            when={this.props.unsavedChanges}
            message={(location) => this.handleUnlock(location)}
            />
        )
    }
}

export default withRouter(PageLock);