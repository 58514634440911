import React, { useContext } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Button, Card, Row, Col, CardBody, CardFooter, UncontrolledTooltip, ListGroup, ListGroupItem, CardHeader, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
import { OmniApi } from '../customAPI/OmniApi';
import { UserContext } from '../custom-contexts/UserContext';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';
import contentCopy from '@iconify-icons/mdi/content-copy';
import phoneIcon from '@iconify-icons/uil/phone';
import envelopeIcon from '@iconify-icons/uil/envelope';
import linkVariant from '@iconify-icons/mdi/link-variant';
import userIcon from '@iconify-icons/uil/user';
import domainIcon from '@iconify/icons-mdi/domain';
import clipboardAccount from '@iconify/icons-mdi/clipboard-account';
import cancelIcon from '@iconify/icons-mdi/cancel';
import { confirm } from "./Confirm";
import { Helmet } from 'react-helmet';
import plusThick from '@iconify/icons-mdi/plus-thick';

function isEmpty(obj) {
    return Object.keys(obj).length === 0;
}


const QuoteDetail = (props) => {

    const { user } = useContext(UserContext);

    const [quote, setQuote] = useState(null);

    function isEmpty(obj) {
        return Object.keys(obj).length === 0;
    }

    const getFS1Image = (img, size) => {
        return `https://fs1.cloudsnob.com/static/${user.company.fs1GetToken}/${img}/${size}`
    }

    useEffect(() => {
        let quoteToken = props.match.params.quoteToken;
        if (!quoteToken) {
            props.history.replace('/omni/quotes')
            // setQuote({
            //     prodName: '',
            //     prodSlug: '',
            //     prodToken: '',
            //     prodImage: '',
            //     prodImages: [],
            //     prodOptions: [],
            //     specs: [],
            //     specialValues: [],
            //     variants: [],
            //     prodVisible: 1
            // })
        }

        OmniApi.get(`/quotes/${quoteToken}`)
            .then(res => {
                if (isEmpty(res.data))
                    throw new Error('Invalid Product Token')

                setQuote(res.data)
            })
            .catch(err => {
                props.history.replace('/omni/quotes')
                toast.error(err.message)
            })
    }, [])

    if(!quote)
    return (
        <div className='page-loading-loader-div w-100 d-flex justify-content-center align-items-center'>
            <div>
            <h4>Loading Quote...</h4>
                <div className="bouncing-loader">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                </div>
            </div>
    )

    function resetStatus(status){
        setQuote({
            ...quote,
            status
        })
    }

    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Quote Detail | {process.env.REACT_APP_HEADER_TITLE}</title>
        </Helmet>
        <div className='d-flex align-items-center'>
        <h2>Quote # {quote.quoteNumber}</h2>
        <div className="d-flex align-items-center ml-3">
            <h3 className="d-flex align-items-center ml-1"><span className={`badge badge-secondary`}>{quote.status}</span></h3>
        </div>
        {quote.status !== 'adminDeclined' && <div className='ml-auto'>
            <DeclineQuoteButton quoteToken={quote.quoteToken} resetStatus={resetStatus}/>
        </div>}
        </div>
        <Row className='justify-content-between'>
            <Col md={9}>
                <VariantDetailsList variantDetails={quote.variantDetails} getFS1Image={getFS1Image} />
            </Col>
            <Col>
                <QuoteSummary quote={quote} />
            </Col>
        </Row>
        <Row>
            <Col sm={'auto'}>
                <CustomerInfo customerObject={quote.customerObject}/>
            </Col>
            <Col sm={'auto'}>
                <ContactInfo contactDetails={quote.contactDetails}/>
            </Col>
            <Col sm={'auto'}>
                <ShippingInfo shippingAddress={quote.shippingAddress}/>
            </Col>
        </Row>
        <Row>
            <Col sm={'auto'}>
            <ShippingQuotes shippingQuotes={quote.shippingQuote} />
            </Col>
        </Row>
        </>
    )
}

const DeclineQuoteButton = props => {
    async function declineQuote(){
        if(await confirm('Would you like to decline this quote? This cannot be reversed.', "Decline Quote", "Decline") === false)
            return
        
            OmniApi.post(`/quotes/${props.quoteToken}/admin-decline`)
            .then(res => {
                toast.success('Quote successfully declined')
                props.resetStatus('adminDeclined')
            })
            .catch(err => {
                toast.error('Error declining quote')
            })
    }

    return (
        <Button
        color="danger"
        onClick={declineQuote}
        className="d-flex align-items-center"
        size='LG'
        >
            <b><Icon icon={cancelIcon} width="23" /></b> <span className='ml-1'>Decline Quote</span>
        </Button>
    )
}

const VariantDetailsList = props => {
    

    const {variantDetails, getFS1Image} = props;

    return(
        <Card>
                    {/* <CardHeader className="d-flex bg-light justify-content-around" style={{height: '52.88px'}}>
                        <div style={{width: '50%'}}><strong>Product</strong></div>
                        <div style={{width: '16%'}}><strong>Price</strong></div>
                        <div style={{width: '16%'}}><strong>Qty</strong></div>
                        <div style={{width: '16%'}}><strong>SubTotal</strong></div>
                    </CardHeader> */}
                    {variantDetails && variantDetails.map((variant, index) => (
                        <div key={index}>
                        {(index > 0) ? <hr className="m-0"/> : ''}
                            <CardBody>
                            <Row className="d-flex flex-nowrap">
                                <div style={{overflow: "hidden", minWidth: '80x', maxWidth: '80px', maxHeight: '80px', flexShrink: 0 }} className="rounded">
                                    <img style={{ height: '80px', width: '80px' }} src={!isEmpty(variant.fullDetails.variantImage) ? getFS1Image(variant.fullDetails.variantImage, 80) : "http://dummyimage.com/80"} />
                                </div>
                                <div className="ml-2"><Link to={`/omni/catalog/product/${variant.fullDetails.prodToken}/${variant.variantToken}`} className="mb-2" style={{ fontSize: '20px' }}>
                                    {variant.fullDetails.variantName}
                                </Link><br/>
                                {variant.fullDetails.variantOptions && variant.product.variantOptions.map((option, i) => (
                                    <div key={i}>
                                    <strong>{option.optionName}: </strong>{option.optionValue}<br/>
                                    </div>
                                ))}
                                </div>
                                <div className="ml-auto mr-1" style={{minWidth: '13%', whiteSpace: 'nowrap'}}>
                                    Price:<br />
                                    <b>{parseFloat(variant.itemPrice).toFixed(2)}</b></div>
                                    <div style={{minWidth: '13%', whiteSpace: 'nowrap'}} className="mr-1">
                                Tax:<br />
                                <b>{variant.itemTax}</b></div>
                                <div style={{minWidth: '13%', whiteSpace: 'nowrap'}} className="mr-1">
                                Qty:<br />
                                <b>{variant.quantity}</b></div>
                                <div style={{minWidth: '13%', whiteSpace: 'nowrap'}}>
                                Total <small className='text-muted'>(incl. tax)</small>:<br />
                                <b>${parseFloat(variant.lineTotal).toFixed(2)}</b></div>
                            </Row>
                            </CardBody>
                        </div>
                    ))}
                    <CardFooter>
                        {/* NEW FEATURE */}
                        {/* <div className="d-flex justify-content-between">
                            <p className="mb-0"><a href="#">Print Pick List...</a></p>
                            <p className="mb-0">Total Products : {variantDetailss.length}</p>
                        </div> */}
                        <div className="d-flex justify-content-end">
                            <p className="mb-0">Total Variants: {variantDetails.length}</p>
                        </div>
                    </CardFooter>
                </Card>
    )
}

const QuoteSummary = props => {

    const { quote } = props;

    return (
        <Card>
            <div className="table-responsive">
                <table className="table mb-0">
                    {/* <thead className="bg-light">
                        <tr>
                            <th>Description</th>
                            <th>Price</th>
                        </tr>
                    </thead> */}
                    <tbody>
                        <tr>
                            <td>Variants Subtotal :</td>
                            <td className="text-right">{quote.variantsTotal}</td>
                        </tr>
                        <tr>
                            <td>Shipping Charge :</td>
                            <td className="text-right">{quote.shippingTotal}</td>
                        </tr>
                        <tr>
                            <td>Estimated Tax : </td>
                            <td className="text-right">{quote.taxTotal}</td>
                        </tr>
                        <tr>
                            <th>Total :</th>
                            <th className="text-right">${quote.combinedTotal}</th>
                        </tr>
                    </tbody>
                </table>
            </div>
        </Card>
    );
};

const CustomerInfo = props => {

    const {customerObject} = props;

    const copyToClipboard = (e, text) => {
        e.preventDefault()
        navigator.clipboard.writeText(text);
    }

    return (
        <React.Fragment>
            <Card style={{ width: '400px' }}>
                <CardBody>
                    <h4>Customer Info</h4>
                    <hr />
            <address className="mb-0 font-17 address-lg">
                <p className="font-weight-bold font-17"><abbr title="User">
                    <Icon icon={userIcon} width="24" height="24" /></abbr> 
                    <span className='ml-1'>{(customerObject.customerFirstName && customerObject.customerLastName) ? `${customerObject.customerFirstName} ${customerObject.customerLastName}` : <i>****Missing Name****</i>}</span> 
                    <Link to={`/omni/customer/${customerObject.customerToken}`} id="user-link" className="ml-1"><Icon icon={linkVariant} width="20" height="20" /></Link>
                    <br /></p>
                <p className="font-weight-bold font-17"><abbr title="Company">
                    <Icon icon={domainIcon} width="24" height="24" /></abbr> 
                    <span className='ml-1 font-weight-normal'>{customerObject.customerCompanyName}</span> 
                    <br /></p>
                <p><abbr title="Email"><Icon icon={envelopeIcon} width="24" height="24" /></abbr> <span className='ml-1'>{customerObject.customerEmail} </span>
                <a href="#" id="copy-email" onClick={(e) => copyToClipboard(e, customerObject.customerEmail)} className="ml-1"><Icon icon={contentCopy} width="20" height="20" /></a>
                {/* <a href="#" id="send-email" onClick={(e) => e.preventDefault()}><Icon icon={emailSendOutline} width="22" height="22" /></a> */}
                <br /></p>
                <UncontrolledTooltip placement="top" target="copy-email" trigger="legacy">
                    '{customerObject.customerEmail}' copied to clipboard
                </UncontrolledTooltip>
                <p><abbr title="Phone"><Icon icon={phoneIcon} width="24" height="24" /></abbr> <span className='ml-1'>{customerObject.customerPhone}</span> 
                    {customerObject.customerPhone && <a href="#" id="copy-phone" onClick={(e) => copyToClipboard(e, customerObject.customerPhone)} className="ml-1"><Icon icon={contentCopy} width="20" height="20" /></a>}
                </p>
                {customerObject.customerPhone && <UncontrolledTooltip placement="top" target="copy-phone" trigger="legacy">
                    '{customerObject.customerPhone}' copied to clipboard
                </UncontrolledTooltip>}
                <p className="font-weight-bold font-17"><abbr title="Status">
                    <Icon icon={clipboardAccount} width="24" height="24" /></abbr> 
                    <span className='ml-1 font-weight-normal'>{customerObject.customerStatus}</span> 
                    <br /></p>
            </address>
            </CardBody>
            </Card>
        </React.Fragment>
    );
};

const ContactInfo = props => {

    const { contactDetails } = props;

    const copyToClipboard = (e, text) => {
        e.preventDefault()
        navigator.clipboard.writeText(text);
    }

    return (
        <React.Fragment>
            <Card style={{ width: '400px' }}>
                <CardBody>
                    <h4>Contact Details</h4>
                    <hr />
            <address className="mb-0 font-17 address-lg">
                <p className="font-weight-bold font-17"><abbr title="User">
                    <Icon icon={userIcon} width="24" height="24" /></abbr> 
                    <span className='ml-1'>{(contactDetails.firstName && contactDetails.lastName) ? `${contactDetails.firstName} ${contactDetails.lastName}` : <i>****Missing Name****</i>}</span> 
                    <br /></p>
                <p className="font-weight-bold font-17"><abbr title="Company">
                    <Icon icon={domainIcon} width="24" height="24" /></abbr> 
                    <span className='ml-1 font-weight-normal'>{contactDetails.companyName}</span> 
                    <br /></p>
                <p><abbr title="Email"><Icon icon={envelopeIcon} width="24" height="24" /></abbr> <span className='ml-1'>{contactDetails.email} </span>
                <a href="#" id="copy-email-contact" onClick={(e) => copyToClipboard(e, contactDetails.email)} className="ml-1"><Icon icon={contentCopy} width="20" height="20" /></a>
                {/* <a href="#" id="send-email" onClick={(e) => e.preventDefault()}><Icon icon={emailSendOutline} width="22" height="22" /></a> */}
                <br /></p>
                <UncontrolledTooltip placement="top" target="copy-email-contact" trigger="legacy">
                    '{contactDetails.email}' copied to clipboard
                </UncontrolledTooltip>
                <p><abbr title="Phone"><Icon icon={phoneIcon} width="24" height="24" /></abbr> <span className='ml-1'>{contactDetails.phoneNumber}</span> 
                    {contactDetails.phoneNumber && <a href="#" id="copy-phone-contact" onClick={(e) => copyToClipboard(e, contactDetails.phoneNumber)} className="ml-1"><Icon icon={contentCopy} width="20" height="20" /></a>}
                </p>
                {contactDetails.phoneNumber && <UncontrolledTooltip placement="top" target="copy-phone-contact" trigger="legacy">
                    '{contactDetails.phoneNumber}' copied to clipboard
                </UncontrolledTooltip>}
            </address>
            </CardBody>
            </Card>
        </React.Fragment>
    );
};

const ShippingInfo = props => {

    const {shippingAddress} = props;

    return (
        <React.Fragment>
            <Card style={{ width: '400px' }}>
                <CardBody>
                    <h4>Shipping Address</h4>
                    <hr />
                    <h5>{shippingAddress.addressFirstName} {shippingAddress.addressLastName}</h5>
                    <address className="mb-0 font-14 address-lg">
                        {shippingAddress.address}, {shippingAddress.address2}
                        <br />
                        {shippingAddress.addressCity}, {shippingAddress.addressState} {shippingAddress.addressZip}
                        <br />
                        <abbr title="Phone">P:</abbr> {shippingAddress.phoneNumber} <br />
                        <abbr title="Mobile">M:</abbr> {shippingAddress.mobileNumber}
                    </address>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

const ShippingQuotes = props => {
    const { shippingQuotes } = props;

    const [isShippingQuoteModalOpen, setIsShippingQuoteModalOpen] = useState(false)

    function toggleShippingQuoteModal(){
        setIsShippingQuoteModalOpen(prevState => !prevState)
    }

    return (
        <Card>
            <CardBody>
                <Row className='justify-content-between'>
                    <Col>
                        <h4>Shipping Quotes</h4>
                    </Col>
                    <Col xs="auto">
                        <Button 
                        color='primary'
                        onClick={toggleShippingQuoteModal}
                        >
                            <Icon icon={plusThick} /><span className='ml-1'>Add Shipping Quote</span>
                        </Button>
                        <ShippingQuoteModal 
                            isOpen={isShippingQuoteModalOpen}
                            toggle={toggleShippingQuoteModal}
                        />
                    </Col>
                </Row>
            <hr />
            <ListGroup flush>
                    {
                    shippingQuotes.length ?
                    shippingQuotes.map((quote, i) => (
                        <ListGroupItem key={i}>
                        <div className="d-flex justify-content-between" style={{ width: '500px' }}>
                        {/* <div style={{ width: '60%' }} clatifyssName="d-flex justify-content-between align-items-center">
                        <span>UPS</span> <span><a href="#">543232482349234</a></span> <span><a href="#"><Icon icon={printerOutline} width="30" height="30" color="grey" /></a></span>
                        </div> */}
                        <div><strong>{quote.carrier}</strong></div>
                        <div>⚬</div>
                        <div>{quote.methodName}</div>
                        <div>⚬</div>
                        <div><strong>{quote.deliveryDays}</strong>{' '}<small>days</small></div>
                        <div>⚬</div>
                        <div>${' '}<strong>{quote.rate}</strong></div>
                        </div>
                    </ListGroupItem>
                    ))
                    :
                    <div className="h-100 w-100 bg-light py-2 rounded d-flex justify-content-center" style={{ minWidth: '500px' }}>
                        <h5>No Shipping Quotes</h5>
                    </div>
                }                      
                </ListGroup>
                </CardBody>
        </Card>
    )
}

const ShippingQuoteModal = ({ isOpen, toggle }) => {

    const [shippingQuote, setShippingQuote] = useState({
        carrier: "",
        methodName: "",
        origRate: "",
        rate: "",
        deliveryDateTime: "",
        deliveryDays: "",
        description: ''
    })

    return(
        <Modal
            isOpen={isOpen}
            toggle={toggle}>
            <ModalHeader toggle={toggle}>Modal title</ModalHeader>
            <ModalBody>

            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={toggle}>
                    Do Something
                </Button>{' '}
                <Button color="secondary" onClick={toggle}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default QuoteDetail;