import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

const Dashboard = () => {

    const [unsavedChanges, setUnsavedChanges] = useState(true)

    return(
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Dashboard | {process.env.REACT_APP_HEADER_TITLE}</title>
            </Helmet>
            <h2>Dashboard coming soon</h2>
        </>
    )
}


export default Dashboard;