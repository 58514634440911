import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { useHistory, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import { OmniApi, XOmniApi } from '../customAPI/OmniApi';

export const UserContext = createContext();

// export default function useUser(){
//     return useContext(UserContext)
// }

export const UserProvider = (props) => {
    const [user, setUser] = useState(null);

    const [omniToken, setOmniToken] = useState(null);

    const [userUpdated, setUserUpdated] = useState(false);

    // set loading while trying to log in
    const [userLoading, setUserLoading] = useState(false);

    // set loading while checking if user is logged in. This is checked every time page is refreshed
    const [checkingUserStatus, setCheckingUserStatus] = useState(true);

    let history = useHistory();
    let location  = useLocation();

    useMemo(() => {
        let userToken = localStorage.getItem('OMNI_TOKEN');
        if (userToken){
            OmniApi.defaults.headers.common['token'] = userToken;
            XOmniApi.defaults.headers.common['token'] = userToken;
        }
    }, [])

    useEffect(() => {
        if(user){
            clearTimeout(setUserTimeout);
            setCheckingUserStatus(false)
        }
    }, [user])

    let setUserTimeout;

    useEffect(() => {
        let userToken = localStorage.getItem('OMNI_TOKEN');
        setOmniToken(userToken);
        if (omniToken){
            axios.get(`${process.env.REACT_APP_OMNI_API}/users/${omniToken}`, {
                headers: {
                  token: omniToken
                }
               })
            .then(res => setUser(res.data))
            .catch(err => setUser(null)) 
            .finally(() => {
                OmniApi.defaults.headers.common['token'] = omniToken;
                XOmniApi.defaults.headers.common['token'] = omniToken;
            })
        } else {
            setUser(null);
            setUserTimeout = setTimeout(() => {
                setCheckingUserStatus(false)
            }, 1000) 
        }

    }, [omniToken])

    const loginUser = (loginInfo) => {
        setUserLoading(true);
		return axios.post(process.env.REACT_APP_OMNI_API + '/login', loginInfo).
        then(res => {
			if(res.data.error){
                console.log(res)
                toast.error(`${res.data.error}`, {
                    theme: 'colored',
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                return false;
			} else if(res.data.userToken) {
				localStorage.setItem('OMNI_TOKEN', res.data.userToken);
                axios.get(`${process.env.REACT_APP_OMNI_API}/users/${res.data.userToken}`, {
                    headers: {
                      token: res.data.userToken
                    }
                   })
                .then(res => {
                    setUser(res.data)
                    OmniApi.defaults.headers.common['token'] = res.data.userToken;
                    XOmniApi.defaults.headers.common['token'] = res.data.userToken;
                    if(location.state && location.state.from)
                    history.push(location.state.from)
                    else
                    history.push('/')
                    console.log(res.data)
                    toast.success('Welcome!', {
                        theme: 'colored',
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    return true;
                })
                .catch(err => setUser(null))
                .finally(() => setUserLoading(false));
			}
		})
        .catch(err => console.log(err))
        .finally(() => setUserLoading(false));
	}

    const isLoggedIn = () => {
        return user ? true : false;
    }

    const logoutUser = () => {
        localStorage.removeItem('OMNI_TOKEN');
        OmniApi.defaults.headers.common['token'] = undefined;
        XOmniApi.defaults.headers.common['token'] = undefined;
        toast.success("User logged out", {
            theme: 'colored',
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        setUser('');
        history.push('/account/login');
        // !!!!!!!!!!!!!!!!!!! IMPORTANT: REMOVE TOKEN FROM AXIOS DEFAULTS. OMNI API !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    } 


    
    return (
        <UserContext.Provider value={{ user, userLoading, userUpdated, checkingUserStatus, loginUser, logoutUser, isLoggedIn }}>
            {props.children}
        </UserContext.Provider>
    );
}

