import React, { useState, useContext } from 'react';
import axios from 'axios';
import { Modal, ModalHeader, ModalBody, UncontrolledTooltip } from 'reactstrap';
//icons
import { Icon, InlineIcon } from '@iconify/react';
import trashIcon from '@iconify-icons/uil/trash';
import eyeIcon from '@iconify-icons/uil/eye';
import moviePlay from '@iconify/icons-mdi/movie-play';
import videoIcon from '@iconify/icons-mdi/video';
import cameraIcon from '@iconify/icons-mdi/camera';
import ReactPlayer from 'react-player/youtube';
import contentCopy from '@iconify-icons/mdi/content-copy';
import { UserContext } from '../custom-contexts/UserContext';

const ImageUpload = (props) => {

    const { user } = useContext(UserContext);

    const [videoModal, setVideoModal] = useState(false);

    const [imageModal, setImageModal] = useState(false);

    const toggleVideoModal = () => {
        setVideoModal(!videoModal)
    }

    const toggleImageModal = e => {
        e.stopPropagation(e)
        setImageModal(!imageModal);
    }

    const [isShown, setIsShown] = useState(false);

    const imageUploader = React.useRef(null);

    const getFS1Image = (img, size = props.imageSize) => {
        return `https://fs1.cloudsnob.com/static/${user.company.fs1GetToken}/${img}/${size}`
    }

    const deleteImage = (e) => {
        e.stopPropagation(e);
        props.deleteImage(props.status || '', props.id || '', props.image || '');
        // The type of the file input had to be reset in order to be able to upload the same image that had justb been deleted. If the type would not be reset the value of the image would stay the same and the onchange attribute would not run when attempting to upload the same image.
        const imageUpload = document.querySelector(!!props.id ? `.image-upload-${props.id}` : '.image-upload')
        console.log(imageUpload)
        imageUpload.type = '';
        imageUpload.type = 'file';
    }

    const openVideo = (e) => {
        e.stopPropagation(e);
        toggleVideoModal()
    }

    const addDefaultSrc = (e) => {
        e.target.src = "";
        e.target.alt = 'invalid image'
    }

    return(
        <div className={`${!!props.id ? 'upload-box-list' : 'upload-box'} ${props.small && 'upload-box-small'}`}
        onClick={props.id ? (e) => e.preventDefault(e) : () => imageUploader.current.click()}
        >
            {!props.image && <div className="upload-box-label d-flex flex-column justify-content-center align-items-center">
                <i className="h1 text-muted dripicons-cloud-upload upload-icon"></i>
                {props.small ? <h6>Upload Image</h6> : <h5>Click to upload image</h5>}</div>}
            <input
                type="file"
                accept="image/*"
                className={!!props.id ? `image-upload-${props.id}` : 'image-upload'}
                onChange={(e) => {
                    console.log('changed');
                    props.handleImageUpload(e)
                }}
                ref={imageUploader}
                style={{
                display: "none"
                }}
            />
            {props.image && 
            <div 
            className="upload-box-img-div"
            onMouseEnter={() => setIsShown(true)}
            onMouseLeave={() => setIsShown(false)}
            >
            <img 
            className="upload-box-img" 
            onError={addDefaultSrc}
            src={props.type === 'youtube' ? 'https://img.youtube.com/vi/' + props.image + '/0.jpg' : getFS1Image(props.image)}
            />
            {(isShown && !props.noHover) && (
                !!props.id ?
                <div className={`${props.noHover ? 'nohover' : ''} icon-div-list d-flex align-items-end justify-content-center`}>
                    {props.type === 'youtube'
                    ?
                    <a onClick={(e) => openVideo(e)}><Icon icon={moviePlay} width="30px" height="30px" color="#404040" className="image-icons" />
                    </a>
                    :
                    <a onClick={(e) => toggleImageModal(e)} target="_blank"><Icon icon={eyeIcon} width="30px" height="30px" color="#404040" className="image-icons" /></a>
                    }
                    {props.allowDelete && <a onClick={(e) => deleteImage(e)}><Icon icon={trashIcon} width="30px" height="30px" color="#404040" className="image-icons" /></a>}
                </div>
                :
                <div className={!!props.id ? 'icon-div-list' : 'icon-div' + " d-flex flex-column align-items-center justify-content-between"}>
                    <div style={{'visibility': 'hidden'}}>Invisible</div>
                    <a onClick={(e) => e.stopPropagation()} href={getFS1Image(props.image, 500)} target="_blank"><Icon icon={eyeIcon} width="70px" height="70px" color="#404040" className="image-icons" /></a>
                    <a onClick={(e) => deleteImage(e)}><Icon icon={trashIcon} width="30px" height="30px" color="#404040" className="image-icons" /></a>
                </div>
            )}
            </div>}
            <span className="badge badge-info image-icon"><Icon icon={props.type === 'youtube' ? videoIcon : cameraIcon} width="15px" color="white" /></span>
            <VideoModal modal={videoModal} toggle={toggleVideoModal} image={props.image} />
            <ImageModal modal={imageModal} toggle={toggleImageModal} image={props.image} getFS1Image={getFS1Image} />
        </div>
    )
}

const VideoModal = props => {

    const {modal, toggle, image} = props;

    const copyToClipboard = (e, text) => {
        e.preventDefault()
        navigator.clipboard.writeText(text);
    }

    return(
        <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} className="modal-colored-header bg-primary">
                Play Video
            </ModalHeader>
            <ModalBody>
                <ReactPlayer
                    width="100%"
                    url={`https://www.youtube.com/watch?v=${image}`}
                    controls={true} 
                />
                <div className="mt-2">
                <p><strong>Video URL:</strong> https://www.youtube.com/watch?v={image}
                <a href="#" id="copy-link" onClick={(e) => copyToClipboard(e, `https://www.youtube.com/watch?v=${image}`)} className="ml-1"><Icon icon={contentCopy} width="20" height="20" /></a>
                </p>
                </div>
                <UncontrolledTooltip placement="top" target="copy-link" trigger="legacy">
                    "https://www.youtube.com/watch?v={image}" copied to clipboard
                </UncontrolledTooltip>
            </ModalBody>
        </Modal>
    )
}

const ImageModal = props => {

    const {modal, toggle, image, getFS1Image} = props;

    return(
        <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} className="modal-colored-header bg-primary">
                Image
            </ModalHeader>
            <ModalBody>
                <img src={getFS1Image(props.image, 500)} style={{ width: '100%' }}></img>
            </ModalBody>
        </Modal>
    )
}

export default ImageUpload;