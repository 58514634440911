import React from 'react';
import { Helmet } from 'react-helmet';

const Apps = () => (
    <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Apps | {process.env.REACT_APP_HEADER_TITLE}</title>
        </Helmet>
        <h2>Apps coming soon...</h2>
</>
)

export default Apps;