import React, { Component } from 'react';
import {
    // Row,
    // Col,
    Card,
    CardBody,
    // CustomInput,
    // UncontrolledDropdown,
    // DropdownToggle,
    // DropdownMenu,
    // DropdownItem,
} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
// import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
// import { select } from '@redux-saga/core/effects';
import axios from 'axios';



class Table extends Component {

    constructor(props){
        super(props)
        this.state={
            selectedRows: [],
            products: []
        }
    }

    columns = [
        {
            dataField: 'prodName',
            text: 'Item Name',
            sort: true,
        },
        {
            dataField: '',
            text: 'Inventory',
            sort: true,
            formatter: (cell, row) => {
                console.log('format', row)
                return `${row.totalCount || 0} in stock from ${row.totalVariants} variants`;
            }
        },
        {
            dataField: 'prodNumber',
            text: 'SKU',
            sort: false,
        },
        {
            dataField: 'visibility',
            text: 'Visibility',
            sort: false,
        },
        {
            dataField: 'prodBrand',
            text: 'Brand',
            sort: false,
        },
        {
            dataField: 'prodPrice',
            text: 'Price',
            sort: false,
        }
    ];
    
    defaultSorted = [
        {
            dataField: 'id',
            order: 'asc',
        },
    ];

    componentDidMount(){
        let userToken = localStorage.getItem('OMNI_TOKEN');
        axios.get(`https://dev.api.omni.cloudsnob.com/products/solr?start=0&rows=60&sortOrder=desc`, {
            headers: {
              'token': userToken
            }
          })
        .then(res => this.setState({ products: res.data.docs }))
    }

    customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total ml-2">
            Showing <strong>{from}</strong> to <strong>{to}</strong> of <strong>{size}</strong> Results
        </span>
    );

    paginationOptions = {
        paginationSize: 10,
        pageStartIndex: 1,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        showTotal: true,
        paginationTotalRenderer: this.customTotal,
        sizePerPageList: [
            {
                text: '20',
                value: 20,
            },
            {
                text: '40',
                value: 40,
            },
            {
                text: '60',
                value: 60,
            },
            {
                text: 'All',
                value: 0,
            },
        ], // A numeric array is also available. the purpose of above example is custom the text
    };

    rowEvents = {
        onClick: (e, row) => {
            console.log(row);
        }
    }

    selectRow = {
        mode: 'checkbox',
        style: { background: '#CCD0FB' },
        clickToSelect: false,
        onSelectAll: (isSelect, rows, e) => {
            if (isSelect){
                this.setState({ selectedRows: rows.map(e => e.prodToken) });
            } else {
                this.setState({ selectedRows: [] });
            }
          },
        onSelect: (row, isSelect, rowIndex, e) => {
            // console.log(row, isSelect, rowIndex);
            if (isSelect){
                this.setState({ selectedRows: [...this.state.selectedRows, row.prodToken] });
            } else {
                this.setState({ selectedRows: this.state.selectedRows.filter(e => e !== row.prodToken) });
            }
          },
        selectionRenderer: ({ checked, disabled }) => {
            return (
                <div className="custom-control custom-checkbox">
                    <input
                        type="checkbox"
                        className="custom-control-input row-checkbox"
                        checked={checked}
                        disabled={disabled}
                        onChange={() => {}}
                    />
                    <label
                        className="custom-control-label row-checkbox-label"
                        onClick={e => {
                            e.preventDefault();
                        }}></label>
                </div>
            );
        },
        selectionHeaderRenderer: ({ indeterminate, ...rest }) => {
            return (
                <div className="custom-control custom-checkbox select-header">
                    <input
                        type="checkbox"
                        className="custom-control-input"
                        id="customCheck1"
                        ref={input => {
                            if (input) input.indeterminate = indeterminate;
                        }}
                        {...rest}
                        onChange={() => {}}
                    />
                    <label
                        className="custom-control-label"
                        htmlFor="customCheck1"
                        onClick={e => {
                            e.preventDefault();
                        }}></label>
                </div>
            );
        },
    };

    rowClasses = (row, rowIndex) => {
        return 'table-row';
      };

    render(){
        return(
            <Card className="mt-3">
                <CardBody>
            <BootstrapTable
                        bootstrap4
                        keyField="prodToken"
                        data={this.state.products}
                        columns={this.columns}
                        defaultSorted={this.defaultSorted}
                        pagination={paginationFactory(this.paginationOptions)}
                        wrapperClasses="table-responsive"
                        classes="product-table"
                        bordered={ false }
                        rowEvents={ this.rowEvents }
                        selectRow={ this.selectRow }
                        rowClasses={ this.rowClasses }
                        hover
                    />
                    </CardBody>
                    </Card>
        )
    }
}

export default Table;